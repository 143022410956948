import React, { useState } from 'react';
import TitleText from '../Helpers/TitleText';
import Description from '../Helpers/Description';
import { Helmet } from "react-helmet";

const PizzaCalculator = () => {
  const [pizzas, setPizzas] = useState([{ size: '', cost: '' }]);

  const mainText = `<p>Use this calculator to find the best value pizza. You can compare different sizes and prices of pizzas from different pizza chains, or different sizes and price points from the same pizza shop. In general the larger the pizza size the better the deal.</p>`;

  const handleAddPizza = () => {
    setPizzas([...pizzas, { size: '', cost: '' }]);
  };

  const handleDeletePizza = (index) => {
    setPizzas(pizzas.filter((_, i) => i !== index));
  };

  const handleInputChange = (index, field, value) => {
    const updatedPizzas = [...pizzas];
    updatedPizzas[index][field] = value;
    setPizzas(updatedPizzas);
  };

  const calculateCostPerUnit = (size, cost) => {
    const radius = size / 2; // Convert inches to cm if needed
    const area = Math.PI * Math.pow(radius, 2);
    return area > 0 ? (cost / area) : 0;
  };

  const bestDeal = pizzas.reduce((best, pizza, index) => {
    if (pizza.size <= 0 || pizza.size === undefined || pizza.cost <= 0 || pizza.cost === undefined) {
        return best;
    }
    const perUnitCost = calculateCostPerUnit(pizza.size, pizza.cost);
    if (!best || perUnitCost < best.cost) {
      return { index, cost: perUnitCost };
    }
    return best;
  }, null);

  return (
    <div className="container mx-auto p-4">
            <Helmet>
        <title>Pizza Calculator</title>
        <meta
          name="description"
          content="This accurate pizza cost calculator will tell you exactly how much you are paying for a pizza and which pizza size is the best deal."
        />
      </Helmet>
        <TitleText title={"Pizza Cost Calculator"} subtitle={"Which Is The Better Pizza Deal"} />

      <div className="grid grid-cols-8 gap-4 items-center mb-4 mt-4">

        <span>Name</span>
        <span className="col-span-2">Diameter (any unit)</span>
        <span className="col-span-2">Cost</span>
        </div>


      {pizzas.map((pizza, index) => (
        <div key={index} className="grid grid-cols-8 gap-4 items-center mb-4">
          <span className="font-bold">Pizza #{index + 1}</span>
          <input
            type="number"
            placeholder="Size (Diameter)"
            value={pizza.size}
            onChange={(e) => handleInputChange(index, 'size', e.target.value)}
            className="input input-bordered col-span-2"
          />
          <input
            type="number"
            placeholder="Cost"
            value={pizza.cost}
            onChange={(e) => handleInputChange(index, 'cost', e.target.value)}
            className="input input-bordered col-span-2"
          />
          <button onClick={() => handleDeletePizza(index)} className="btn btn-outline btn-error">
            Delete
          </button>
          <span className="text-gray-700 col-span-2">
            Cost per square unit: ${calculateCostPerUnit(pizza.size, pizza.cost).toFixed(4)}
          </span>
        </div>
      ))}

      <button onClick={handleAddPizza} className="btn btn-primary mb-4">
        Add Another Pizza
      </button>

      {bestDeal !== null && (
        <div className="alert alert-info">
          <span>
            Best deal is Pizza #{bestDeal.index + 1} with a cost of ${bestDeal.cost.toFixed(4)} per square unit (inch or cm).
          </span>
        </div>
      )}

        <Description title={"Best Pizza Value Size"} mainText={mainText} />
    </div>
  );
};

export default PizzaCalculator;
