import React, { useState } from "react";
import TitleText from "../Helpers/TitleText";
import Description from "../Helpers/Description";
import { Helmet } from "react-helmet";

function calculateSubnet(ip, mask) {
  const ipArray = ip.split(".").map(Number);
  const maskArray = mask.split(".").map(Number);

  if (ipArray.length !== 4 || maskArray.length !== 4) return null;

  const networkAddress = ipArray
    .map((octet, index) => octet & maskArray[index])
    .join(".");
  const broadcastAddress = ipArray
    .map(
      (octet, index) => (octet & maskArray[index]) | (~maskArray[index] & 255)
    )
    .join(".");

  const numHosts = Math.pow(2, 32 - subnetMaskToCidr(mask)) - 2;
  const firstHost = incrementIP(networkAddress);
  const lastHost = decrementIP(broadcastAddress);

  return {
    networkAddress,
    broadcastAddress,
    numberOfHosts: numHosts,
    firstHost,
    lastHost,
  };
}

function isValidIP(ip) {
  const ipPattern =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  return ipPattern.test(ip);
}

function isValidSubnetMask(mask) {
  const maskPattern =
    /^(255|254|252|248|240|224|192|128|0+)\.(255|254|252|248|240|224|192|128|0+)\.(255|254|252|248|240|224|192|128|0+)\.(255|254|252|248|240|224|192|128|0+)$/;
  return maskPattern.test(mask);
}

function subnetMaskToCidr(mask) {
  return (
    mask
      .split(".")
      .map((octet) => parseInt(octet, 10).toString(2).padStart(8, "0"))
      .join("")
      .split("1").length - 1
  );
}

function cidrToSubnetMask(cidr) {
  const mask = (0xffffffff << (32 - cidr)) >>> 0;
  return [mask >>> 24, (mask >> 16) & 255, (mask >> 8) & 255, mask & 255].join(
    "."
  );
}

function incrementIP(ip) {
  const ipArray = ip.split(".").map(Number);
  ipArray[3] += 1;
  for (let i = 3; i >= 0; i--) {
    if (ipArray[i] > 255) {
      ipArray[i] = 0;
      if (i > 0) ipArray[i - 1] += 1;
    }
  }
  return ipArray.join(".");
}

function decrementIP(ip) {
  const ipArray = ip.split(".").map(Number);
  ipArray[3] -= 1;
  for (let i = 3; i >= 0; i--) {
    if (ipArray[i] < 0) {
      ipArray[i] = 255;
      if (i > 0) ipArray[i - 1] -= 1;
    }
  }
  return ipArray.join(".");
}

const SubnettingCalc = () => {
  const [ipAddress, setIpAddress] = useState("");
  const [subnetMask, setSubnetMask] = useState("");
  const [cidr, setCidr] = useState("");
  const [result, setResult] = useState(null);
  const [error, setError] = useState("");

  const handleIPChange = (e) => {
    setIpAddress(e.target.value);
    setError("");
  };

  const handleSubnetMaskChange = (e) => {
    const mask = e.target.value;
    setSubnetMask(mask);
    setError("");

    if (isValidSubnetMask(mask)) {
      setCidr(subnetMaskToCidr(mask)); // Sync CIDR
    } else {
      setError("Invalid subnet mask.");
    }
  };

  const handleCidrChange = (e) => {
    const cidrValue = e.target.value.replace("\\", "").replace("/", "");
    console.log("V: " + cidrValue);
    setCidr(cidrValue);
    setError("");

    if (cidrValue >= 0 && cidrValue <= 32) {
      setSubnetMask(cidrToSubnetMask(cidrValue)); // Sync subnet mask
    } else {
      setError("Invalid CIDR notation.");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleCalculate();
    }
  };

  const handleCalculate = () => {
    if (!isValidIP(ipAddress)) {
      setError("Invalid IP address.");
      return;
    }
    if (!isValidSubnetMask(subnetMask)) {
      setError("Invalid subnet mask.");
      return;
    }

    const calculationResult = calculateSubnet(ipAddress, subnetMask);
    if (calculationResult) {
      setResult(calculationResult);
      setError("");
    } else {
      setError("Error calculating subnet details.");
    }
  };

  const mainText = `<p>This IPv4 subnetting calculator is a computational tool designed to help network administrators efficiently divide and manage IP network address spaces. By inputting an IP address and subnet mask, the calculator can determine critical network parameters such as network address, broadcast address, total number of available host addresses, usable IP range, and subnet prefix length. It enables quick calculation of subnet details, supports CIDR (Classless Inter-Domain Routing) notation conversion, and helps in planning network segmentation, allocating IP addresses, and configuring network infrastructure.
  </p><p>
  Check out our <a href="/tech/vlsm/" class="link">VLSM subnetting calculator</a> for dividing an existing subnet into smaller subnets.`;

  return (
    <div>
      <Helmet>
        <title>Subnet Converter</title>
        <meta
          name="description"
          content="Get all the useful information for a given subnet, like the broadcast address, number of hosts, etc."
        />
      </Helmet>
      <div className="p-5">
        <TitleText
          title={"Subnet Calculator"}
          subtitle={"IPv4 subnet information"}
        />
        <div className="form-control">
          <label className="label flex items-center space-x-0">
            <span className="label-text w-3/12 text-left">IP Address</span>
            <input
              type="text"
              placeholder="e.g., 192.168.1.1"
              className="input input-bordered w-full"
              value={ipAddress}
              onChange={handleIPChange}
              onKeyDown={handleKeyDown}
            />
          </label>
        </div>

        <div className="form-control">
          <label className="label flex items-center space-x-0">
            <span className="label-text w-3/12 text-left">Subnet Mask</span>
            <input
              type="text"
              placeholder="e.g., 255.255.255.0"
              className="input input-bordered w-full"
              value={subnetMask}
              onChange={handleSubnetMaskChange}
              onKeyDown={handleKeyDown}
            />
          </label>
        </div>

        <div className="form-control">
          <label className="label flex items-center space-x-0">
            <span className="label-text w-3/12 text-left">CIDR Notation</span>
            <span className="input input-bordered flex items-center gap-2 w-full">
              /
              <input
                type="number"
                placeholder="e.g., 24"
                className="w-full"
                value={cidr}
                onChange={handleCidrChange}
                onKeyDown={handleKeyDown}
                min="0"
                max="32"
              />
            </span>
          </label>
        </div>

        {error && <p className="text-red-500 mb-4">{error}</p>}

        <button className="btn btn-primary" onClick={handleCalculate}>
          Calculate
        </button>

        {result && (
          <div className="mt-4">
            <h3 className="text-xl font-semibold">Results:</h3>
            <p>Network Address: {result.networkAddress}</p>
            <p>Broadcast Address: {result.broadcastAddress}</p>
            <p>Number of Hosts: {result.numberOfHosts}</p>
            <p>First Host: {result.firstHost}</p>
            <p>Last Host: {result.lastHost}</p>
          </div>
        )}
      </div>
      <Description title={"IPv4 Subnet Calculator"} mainText={mainText} />
    </div>
  );
};

export default SubnettingCalc;
