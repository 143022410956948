import React, { useState } from "react";
import TitleText from "../Helpers/TitleText";
import Description from "../Helpers/Description";
import { Helmet } from "react-helmet";

const NYCTaxCalculator = () => {
  const [income, setIncome] = useState(0);
  const [filingStatus, setFilingStatus] = useState("single");
  const [federalTax, setFederalTax] = useState(0);
  const [stateTax, setStateTax] = useState(0);
  const [cityTax, setCityTax] = useState(0);
  const [marginalRates, setMarginalRates] = useState({
    federal: 0,
    state: 0,
    city: 0,
  });

  const mainText = `<p>If you live in New York City you must pay federal, state, and local income taxes. That's right, New York City charges an additional income tax along with what you already pay to the federal and state governments. You pay this city tax if you live anywhere inside of New York City, in any borough (Manhattan, The Bronx, Brooklyn, Queens, Staten Island). Yonkers has a separate income tax.</p>
<p>You do not pay New York City income tax if you do not live in the city, unless you are a New York City employee (except teachers, prosecutors, housing authority workers). That's one reason many people move out of NYC to places like New Jersey. Though New Jersey and other surrounding locations tend to have high property taxes. If you work for the New York City government and live outside of NYC you will still pay NYC income taxes. This does not apply to you if you work for the Department of Education, City University of New York, District Attorneys' Offices, or New York City Housing Authority.</p>
<p>NYC income tax helps fund many city programs. This includes education, sanitation, public safety, libraries, and social services. It also includes salaries for all the city workers, including the bureaucrats who make the city run. No one likes paying taxes. The inefficiencies and waste of government bureaucracies is a real problem. At the same time, libraries, schools, parks, and other city funded services are wonderful.</p>
<p>This NYC income tax calculator is a way to estimate how much income tax you will need to pay if you live in New York City. It shows you federal, New York State, and city taxes.</p>`;

  const federalBrackets = {
    single: [
      { rate: 0.1, limit: 11000 },
      { rate: 0.12, limit: 44725 },
      { rate: 0.22, limit: 95375 },
      { rate: 0.24, limit: 182100 },
      { rate: 0.32, limit: 231250 },
      { rate: 0.35, limit: 578125 },
      { rate: 0.37, limit: Infinity },
    ],
    married: [
      { rate: 0.1, limit: 22000 },
      { rate: 0.12, limit: 89450 },
      { rate: 0.22, limit: 190750 },
      { rate: 0.24, limit: 364200 },
      { rate: 0.32, limit: 462500 },
      { rate: 0.35, limit: 693750 },
      { rate: 0.37, limit: Infinity },
    ],
  };

  const stateBrackets = {
    single: [
      { rate: 0.04, limit: 8500 },
      { rate: 0.045, limit: 11700 },
      { rate: 0.0525, limit: 13900 },
      { rate: 0.055, limit: 80650 },
      { rate: 0.06, limit: 215400 },
      { rate: 0.0685, limit: 1077550 },
      { rate: 0.0965, limit: 5000000 },
      { rate: 0.103, limit: 25000000 },
      { rate: 0.109, limit: Infinity },
    ],
    married: [
      { rate: 0.04, limit: 17150 },
      { rate: 0.045, limit: 23600 },
      { rate: 0.0525, limit: 27900 },
      { rate: 0.055, limit: 161550 },
      { rate: 0.06, limit: 323200 },
      { rate: 0.0685, limit: 2155350 },
      { rate: 0.0965, limit: 5000000 },
      { rate: 0.103, limit: 25000000 },
      { rate: 0.109, limit: Infinity },
    ],
  };

  const cityBrackets = {
    single: [
      { rate: 0.03078, limit: 12000 },
      { rate: 0.03762, limit: 25000 },
      { rate: 0.03819, limit: 50000 },
      { rate: 0.03876, limit: Infinity },
    ],
    married: [
      { rate: 0.03078, limit: 21600 },
      { rate: 0.03762, limit: 45000 },
      { rate: 0.03819, limit: 90000 },
      { rate: 0.03876, limit: Infinity },
    ],
  };

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleIncome = (income) => {
    const numIncome = Number(income);
    if (isNaN(numIncome) || income === "") {
      setIncome(income);
      return;
    }
    setIncome(numIncome);
    handleCalculate(filingStatus, numIncome);
  };

  const calculateTax = (brackets, income) => {
    let tax = 0;
    let previousLimit = 0;
    let marginalRate = 0;

    for (const bracket of brackets) {
      if (income <= bracket.limit) {
        tax += (income - previousLimit) * bracket.rate;
        marginalRate = bracket.rate;
        break;
      } else {
        tax += (bracket.limit - previousLimit) * bracket.rate;
        previousLimit = bracket.limit;
      }
    }

    return { tax, marginalRate };
  };

  const handleCalculate = (status, numIncome) => {
    const federal = calculateTax(federalBrackets[status], numIncome);
    const state = calculateTax(stateBrackets[status], numIncome);
    const city = calculateTax(cityBrackets[status], numIncome);

    setFederalTax(federal.tax);
    setStateTax(state.tax);
    setCityTax(city.tax);
    setMarginalRates({
      federal: federal.marginalRate,
      state: state.marginalRate,
      city: city.marginalRate,
    });
  };

  const handleFilingStatus = (status) => {
    setFilingStatus(status);
    handleCalculate(status, income);
  };

  const effectiveRate = (tax) => tax / income || 0;

  return (
    <div>
      <Helmet>
        <title>NYC Tax Calculator</title>
        <meta
          name="description"
          content="Calculate the amount of federal, New York State, and New York City income taxes you will need to pay based on your salary"
        />
      </Helmet>
      <div className="flex flex-col items-center p-4 bg-base-200 rounded-lg shadow-lg max-w-fit mx-auto mt-10">
        <TitleText
          title={"New York City Income Tax Calculator"}
          subtitle={"NYC Income Tax Rates"}
        />
        <div className="mb-4 mt-4">
          <label className="block mb-2">Annual Income:</label>
          <input
            type="number"
            className="input input-bordered w-full"
            value={income}
            onChange={(e) => handleIncome(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Filing Status:</label>
          <select
            className="select select-bordered w-full"
            value={filingStatus}
            onChange={(e) => handleFilingStatus(e.target.value)}
          >
            <option value="single">Single</option>
            <option value="married">Married Filing Jointly</option>
          </select>
        </div>
        <div className="mt-4">
          <h2 className="text-lg font-bold">Results:</h2>
          <p>
            Federal Tax: ${numberWithCommas(federalTax.toFixed(2))} (Marginal:{" "}
            {(marginalRates.federal * 100).toFixed(2)}%, Effective:{" "}
            {(effectiveRate(federalTax) * 100).toFixed(2)}%)
          </p>
          <p>
            State Tax: ${numberWithCommas(stateTax.toFixed(2))} (Marginal:{" "}
            {(marginalRates.state * 100).toFixed(2)}%, Effective:{" "}
            {(effectiveRate(stateTax) * 100).toFixed(2)}%)
          </p>
          <p>
            City Tax: ${numberWithCommas(cityTax.toFixed(2))} (Marginal:{" "}
            {(marginalRates.city * 100).toFixed(2)}%, Effective:{" "}
            {(effectiveRate(cityTax) * 100).toFixed(2)}%)
          </p>
          <p>
            Total Tax: $
            {numberWithCommas((federalTax + stateTax + cityTax).toFixed(2))}{" "}
            (Effective:{" "}
            {(((federalTax + stateTax + cityTax) / income) * 100).toFixed(2)}%)
          </p>
          <p>
            Income After Taxes: $
            {numberWithCommas(
              (income - (federalTax + stateTax + cityTax)).toFixed(2)
            )}
          </p>
        </div>
      </div>
      <Description title={"NYC Taxes"} mainText={mainText} />
    </div>
  );
};

export default NYCTaxCalculator;
