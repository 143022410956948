import React, { useState } from 'react';
import TitleText from '../Helpers/TitleText';
import Description from '../Helpers/Description';
import { Helmet } from 'react-helmet';

const PriceToWageCalculator = () => {
  const [price, setPrice] = useState('');
  const [wage, setWage] = useState('');
  const [overtimePay, setOvertimePay] = useState('');
  const [overtimePayStart, setOvertimePayStart] = useState('');
  const [taxRate, setTaxRate] = useState('');
  const [hours, setHours] = useState(0);

  const mainText = `<p>This calculator will help you see how long you have to work to earn a certain amount. That can then be used to calculate how long you have to work to buy something based on its price.</p>`;

  const calculateHours = () => {
    if (price > 0 && wage > 0) {
      // Calculate hourly wage after taxes
      const wageAfterTax = wage - (wage * (taxRate / 100 || 0));

      const overtimePayStartH = Number(overtimePayStart)
      if (overtimePay > 0 && overtimePayStartH > 0) {
        const regularPay = overtimePayStartH * wageAfterTax;
        const overtimeWage = overtimePay - (overtimePay * (taxRate / 100 || 0));
        if (price <= regularPay) {
          setHours((price / wageAfterTax).toFixed(2));
        } else {
          const overtimeNeeded = price - regularPay;
          console.log(overtimePayStartH + overtimeNeeded / overtimeWage)
          setHours((overtimePayStartH + overtimeNeeded / overtimeWage).toFixed(2));
        }
      } else {
        setHours((price / wageAfterTax).toFixed(2));
      }
    } else {
      setHours(0);
    }
  };

  return (
    <div>
          <Helmet>
              <title>Price To Wage Calculator</title>
              <meta
                name="description"
                content="See how long you would have to work to buy something based on your income/wage."
              />
            </Helmet>
    <div className="flex flex-col items-center p-6 bg-base-200 rounded-lg shadow-lg max-w-md mx-auto mt-10">
      <TitleText title={"Price to Wage Calculator"} subtitle={"How long will you have to work to buy something?"} />

      <div className="form-control w-full max-w-xs mb-4">
        <label className="label">
          <span className="label-text">Price of the Item ($)</span>
        </label>
        <input
          type="number"
          className="input input-bordered w-full max-w-xs"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          placeholder="Enter item price"
        />
      </div>

      <div className="form-control w-full max-w-xs mb-4">
        <label className="label">
          <span className="label-text">Hourly Wage ($)</span>
        </label>
        <input
          type="number"
          className="input input-bordered w-full max-w-xs"
          value={wage}
          onChange={(e) => setWage(e.target.value)}
          placeholder="Enter hourly wage"
        />
      </div>

      <div className="form-control w-full max-w-xs mb-4">
        <label className="label">
          <span className="label-text">Overtime Pay Rate ($/hr, optional)</span>
        </label>
        <input
          type="number"
          className="input input-bordered w-full max-w-xs"
          value={overtimePay}
          onChange={(e) => setOvertimePay(e.target.value)}
          placeholder="Enter overtime pay rate"
        />
      </div>

      <div className="form-control w-full max-w-xs mb-4">
        <label className="label">
          <span className="label-text">Overtime starts after how many hours? (optional)</span>
        </label>
        <input
          type="number"
          className="input input-bordered w-full max-w-xs"
          value={overtimePayStart}
          onChange={(e) => setOvertimePayStart(e.target.value)}
          placeholder="Enter number of hours when overtime pay kicks in"
        />
      </div>

      <div className="form-control w-full max-w-xs mb-4">
        <label className="label">
          <span className="label-text">Tax Percentage (% optional)</span>
        </label>
        <input
          type="number"
          className="input input-bordered w-full max-w-xs"
          value={taxRate}
          onChange={(e) => setTaxRate(e.target.value)}
          placeholder="Enter tax percentage"
        />
      </div>

      <button
        className="btn btn-primary w-full max-w-xs"
        onClick={calculateHours}
      >
        Calculate
      </button>

      {hours > 0 && (
        <div className="mt-4">
          <p className="text-lg">
            It will take <strong>{hours} hours</strong> of work to afford this item.
          </p>
        </div>
      )}
    </div>
    <Description title={"Price Versus Wages"} mainText={mainText} />
    </div>
  );
};

export default PriceToWageCalculator;
