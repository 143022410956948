import React from "react";
import FinanceIndex from "./FinanceIndex";
import { Helmet } from "react-helmet";

const FinanceIndexWithHeader = () => {
  return (
    <>
      <Helmet>
        <title>Finance Calculators</title>
        <meta
          name="description"
          content="A easy to understand set of personal finance calculators."
        />
      </Helmet>
      <FinanceIndex />
    </>
  );
};

export default FinanceIndexWithHeader;
