import React, { useState } from "react";
import TitleText from "../Helpers/TitleText";
import Description from "../Helpers/Description";
import { Helmet } from "react-helmet";

const PtoCalculator = () => {
  const [currentPto, setCurrentPto] = useState(0);
  const [accrualRate, setAccrualRate] = useState(0);
  const [accrualFrequency, setAccrualFrequency] = useState("weekly");
  const [hoursWorkedPerWeek, setHoursWorkedPerWeek] = useState(40);
  const [futureDate, setFutureDate] = useState("");
  const [ptoUsed, setPtoUsed] = useState(0);
  const [ptoCap, setPtoCap] = useState(null);

  const mainText = `<p>To figure out how much paid time off (PTO) you will have in the future you need to find out how much PTO you earn hourly/weekly/biweekly/monthly. Once you have that information you can select any future date and this PTO calculator will let you know how many hours of paid time off you will have earned by then.</p>
<p>Some companies will cap your PTO. You might also already have PTO saved up or plan to use some amount of PTO between now and the target date. This calculator can take any of that into account. If you earn PTO hourly then the calculator will ask how many hours a week you usually work.</p>
<p>This calculator is just an estimate. It doesn't take into account things like holidays and other company policies. Reach out to your HR department if you need to know the exact amount of paid time off you will have at a future date.</p>`;

  const calculatePto = () => {
    if (!futureDate) return 0;

    const today = new Date();
    const endDate = new Date(futureDate);
    const timeDiff = Math.abs(endDate - today);
    const weeks = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 7));
    const months = Math.floor(weeks / 4);

    let totalAccruedPto = 0;

    if (accrualFrequency === "weekly") {
      totalAccruedPto = weeks * Number(accrualRate);
    } else if (accrualFrequency === "biweekly") {
      totalAccruedPto = Math.floor(weeks / 2) * Number(accrualRate);
    } else if (accrualFrequency === "monthly") {
      totalAccruedPto = months * Number(accrualRate);
    } else if (accrualFrequency === "hourly") {
      const totalHoursWorked = weeks * Number(hoursWorkedPerWeek);
      totalAccruedPto = totalHoursWorked * Number(accrualRate);
    }

    // TODO: converting to Number like this is me being lazy.
    let projectedPto =
      Number(currentPto) + Number(totalAccruedPto) - Number(ptoUsed);

    if (ptoCap && projectedPto > Number(ptoCap)) {
      projectedPto = Number(ptoCap);
    }

    return projectedPto.toFixed(2);
  };

  const calculateAccruals = () => {
    if (accrualFrequency === "hourly") {
      return {
        weekly: (Number(accrualRate) * Number(hoursWorkedPerWeek)).toFixed(2),
        monthly: (Number(accrualRate) * Number(hoursWorkedPerWeek) * 4).toFixed(
          2
        ),
        yearly: (Number(accrualRate) * Number(hoursWorkedPerWeek) * 52).toFixed(
          2
        ),
      };
    } else {
      const weeklyRate =
        accrualFrequency === "weekly"
          ? Number(accrualRate)
          : accrualFrequency === "biweekly"
          ? Number(accrualRate) / 2
          : accrualFrequency === "monthly"
          ? Number(accrualRate) / 4
          : 0;

      return {
        weekly: weeklyRate.toFixed(2),
        monthly: (weeklyRate * 4).toFixed(2),
        yearly: (weeklyRate * 52).toFixed(2),
      };
    }
  };

  const accruals = calculateAccruals();
  const projectedPto = calculatePto();

  return (
    <>
      <Helmet>
        <title>PTO Calculator</title>
        <meta
          name="description"
          content="Estimate how much paid time off you will have at any future date. Great for planning a vacation or time off from work."
        />
      </Helmet>
      <div className="container mx-auto p-4">
      <TitleText
          title={"PTO Calculator"}
          subtitle={"How Much Paid Time Off will you have?"}
        />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="form-control">
            <label className="label">
              <span className="label-text">Current PTO Balance (hours)</span>
            </label>
            <input
              type="number"
              className="input input-bordered"
              value={currentPto}
              onChange={(e) => setCurrentPto(e.target.value)}
            />
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Accrual Rate</span>
            </label>
            <input
              type="number"
              className="input input-bordered"
              value={accrualRate}
              onChange={(e) => setAccrualRate(e.target.value)}
            />
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Accrual Frequency</span>
            </label>
            <select
              className="select select-bordered"
              value={accrualFrequency}
              onChange={(e) => setAccrualFrequency(e.target.value)}
            >
              <option value="hourly">Hourly</option>
              <option value="weekly">Weekly</option>
              <option value="biweekly">Every 2 Weeks</option>
              <option value="monthly">Monthly</option>
            </select>
          </div>
          {accrualFrequency === "hourly" && (
            <div className="form-control">
              <label className="label">
                <span className="label-text">Hours Worked Per Week</span>
              </label>
              <input
                type="number"
                className="input input-bordered"
                value={hoursWorkedPerWeek}
                onChange={(e) => setHoursWorkedPerWeek(e.target.value)}
              />
            </div>
          )}
          <div className="form-control">
            <label className="label">
              <span className="label-text">Future Date</span>
            </label>
            <input
              type="date"
              className="input input-bordered"
              value={futureDate}
              onChange={(e) => setFutureDate(e.target.value)}
            />
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">PTO Planned to Use (hours)</span>
            </label>
            <input
              type="number"
              className="input input-bordered"
              value={ptoUsed}
              onChange={(e) => setPtoUsed(e.target.value)}
            />
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">PTO Cap (optional)</span>
            </label>
            <input
              type="number"
              className="input input-bordered"
              value={ptoCap || ""}
              onChange={(e) =>
                setPtoCap(e.target.value ? e.target.value : null)
              }
            />
          </div>
        </div>
        <div className="mt-6">
          <h2 className="text-xl font-semibold">
            Projected PTO on {futureDate || "Future Date"}:{" "}
            <span className="text-info">{projectedPto} hours</span>
          </h2>
          <h3 className="text-lg mt-4">Accrual Rates:</h3>
          <ul className="list-disc list-inside">
            <li>Weekly: {accruals.weekly} hours</li>
            <li>Monthly: {accruals.monthly} hours</li>
            <li>Yearly: {accruals.yearly} hours</li>
          </ul>
        </div>
      </div>
      <Description title={"Calculating Paid Time Off"} mainText={mainText} />
    </>
  );
};

export default PtoCalculator;
