import React, { useState } from 'react';
import { Link } from "react-router-dom";
import TitleText from '../Helpers/TitleText';
import Description from '../Helpers/Description';
import { Helmet } from 'react-helmet';

function TDEECalculator() {
  const [gender, setGender] = useState("male");
  const [weightKg, setWeightKg] = useState("");
  const [weightLbs, setWeightLbs] = useState("");
  const [height, setHeight] = useState("");
  const [heightInches, setHeightInches] = useState("");
  const [age, setAge] = useState("");
  const [bodyFat, setBodyFat] = useState("");
  const [activityLevel, setActivityLevel] = useState(1.2);
  const [tdee, setTDEE] = useState(null);

  const mainText = `<p>Use this TDEE calculator to get a rough idea of how many calories you are burning in a day. From that you can adjust your diet to either lose weight or gain weight/muscle by decreasing or increasing your caloric intake.
</p><p>
TDEE, or Total Daily Energy Expenditure, represents the total number of calories an individual burns in a day, encompassing all physical activities and metabolic processes. This comprehensive measurement includes the energy required for basic life-sustaining functions like breathing and maintaining body temperature (known as basal metabolic rate), as well as calories burned through exercise, daily movements, and digestion.
</p><p>
Estimating TDEE typically involves calculating basal metabolic rate using formulas like the Harris-Benedict or Mifflin-St Jeor equations, which consider factors such as age, gender, height, and weight. Once the basal metabolic rate is determined, this baseline is multiplied by an activity factor that reflects a person's typical daily movement and exercise level, ranging from sedentary to extremely active. This calculation provides an approximate daily calorie expenditure, which can be used for nutritional planning, weight management, athletic performance, and understanding individual metabolic needs.
</p>`;

  const handleKgWeight = (weight) => {
    setWeightKg(weight);
    setWeightLbs((weight * 2.20462).toFixed(2));
  }

  const handleLbsWeight = (weight) => {
    setWeightLbs(weight);
    setWeightKg((weight / 2.20462).toFixed(2));
  }

  const handleHeight = (height) => {
    setHeight(height);
    setHeightInches((height / 2.54 ).toFixed(2));
  }

  const handleHeightInches = (height) => {
    setHeightInches(height);
    setHeight((height * 2.54).toFixed(2));
  }

  const calculateTDEE = () => {
    const weight = parseFloat(weightKg);
    const heightCm = parseFloat(height);
    const ageYears = parseInt(age);
    const bodyFatPercentage = parseFloat(bodyFat) / 100;

    // Mifflin-St Jeor Equation
    const mifflinBMR = gender === "male"
      ? 10 * weight + 6.25 * heightCm - 5 * ageYears + 5
      : 10 * weight + 6.25 * heightCm - 5 * ageYears - 161;

    // Harris-Benedict Equation
    const harrisBMR = gender === "male"
      ? 88.362 + (13.397 * weight) + (4.799 * heightCm) - (5.677 * ageYears)
      : 447.593 + (9.247 * weight) + (3.098 * heightCm) - (4.330 * ageYears);

    // Katch-McArdle Equation
    const leanBodyMass = weight * (1 - bodyFatPercentage);
    const katchBMR = 370 + (21.6 * leanBodyMass);

    // Calculate TDEE
    const mifflinTDEE = mifflinBMR * activityLevel;
    const harrisTDEE = harrisBMR * activityLevel;
    const katchTDEE = katchBMR * activityLevel;

    setTDEE({
      mifflin: mifflinTDEE.toFixed(2),
      harris: harrisTDEE.toFixed(2),
      katch: katchTDEE.toFixed(2),
    });
  };

  return (
    <div>
      <Helmet>
        <title>TDEE Calculator</title>
        <meta
          name="description"
          content="A single calculator that will calculate your TDEE based on multiple EE formulas at the same time."
        />
      </Helmet>
    <div className="p-6 bg-base-100">
            <TitleText title={"TDEE Calculator"} subtitle={"Multiple TDEE calculations at once."} />
      <div className="form-control mb-6">
        <label className="label">Gender</label>
        <select
          className="select select-bordered"
          value={gender}
          onChange={(e) => setGender(e.target.value)}
        >
          <option value="male">Male</option>
          <option value="female">Female</option>
        </select>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        <div className="form-control">
          <label className="label">Weight (kg)</label>
          <input
            type="number"
            className="input input-bordered"
            value={weightKg}
            onChange={(e) => handleKgWeight(e.target.value)}
          />
        </div>
        <div className="form-control">
          <label className="label">Weight (lbs)</label>
          <input
            type="number"
            className="input input-bordered"
            value={weightLbs}
            onChange={(e) => handleLbsWeight(e.target.value)}
          />
        </div>
        <div className="form-control">
          <label className="label">Height (cm)</label>
          <input
            type="number"
            className="input input-bordered"
            value={height}
            onChange={(e) => handleHeight(e.target.value)}
          />
        </div>
        <div className="form-control">
          <label className="label">Height (inches) <Link to="/health/height" className="link">Height Calculator</Link></label>
          <input
            type="number"
            className="input input-bordered"
            value={heightInches}
            onChange={(e) => handleHeightInches(e.target.value)}
          />
        </div>
        <div className="form-control">
          <label className="label">Age (years)</label>
          <input
            type="number"
            className="input input-bordered"
            value={age}
            onChange={(e) => setAge(e.target.value)}
          />
        </div>
        <div className="form-control">
          <label className="label">Body Fat Percentage (Optional)</label>
          <input
            type="number"
            className="input input-bordered"
            value={bodyFat}
            onChange={(e) => setBodyFat(e.target.value)}
          />
        </div>
      </div>
      <div className="form-control mb-6">
        <label className="label">Activity Level</label>
        <select
          className="select select-bordered"
          value={activityLevel}
          onChange={(e) => setActivityLevel(parseFloat(e.target.value))}
        >
          <option value="1.2">Sedentary (little or no exercise)</option>
          <option value="1.375">Lightly active (1–3 days per week)</option>
          <option value="1.55">Moderately active (3–5 days per week)</option>
          <option value="1.725">Very active (6–7 days per week)</option>
          <option value="1.9">Super active (hard physical job)</option>
        </select>
      </div>
      <button className="btn btn-primary mb-6" onClick={calculateTDEE}>
        Calculate TDEE
      </button>
      {tdee && (
        <div className="mt-6">
          <h2 className="text-xl font-bold">Results</h2>
          <p>Mifflin-St Jeor TDEE: {tdee.mifflin} kcal</p>
          <p>Harris-Benedict TDEE: {tdee.harris} kcal</p>
          {isNaN(tdee.katch) ? <span/> : <p>Katch-McArdle TDEE: {tdee.katch} kcal</p>}
        </div>
      )}
    </div>
    <Description title={"Multiple TDEE Calculators"} mainText={mainText} />
    </div>
  );
}

export default TDEECalculator;
