import React from 'react';
import LinkList from '../Helpers/LinkList';

const TechIndex = () => {
    const links = [
        { title: 'Oklch Picker/Converter', url: '/tech/oklch/', description: 'OKLCH color converter into RGB, HEX, HSL' },
        { title: 'Subnet Calculator', url: '/tech/subnet/', description: 'IPv4 subnet information' },
        { title: 'VLSM Subnetting Calculator', url: '/tech/subnet/', description: 'Dividing IPv4 networks into subnets' },
      ];
    
      return (
          <div className="flex flex-col items-center p-4">
            <h2 className="text-2xl font-bold mb-4">Technology Calculators</h2>
            <LinkList links={links} />
          </div>
      );
};

export default TechIndex;