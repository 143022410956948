import React from "react";
import HealthIndex from "./HealthIndex";
import { Helmet } from "react-helmet";

const HealthIndexWithHeader = () => {
  return (
    <>
      <Helmet>
        <title>Health Calculators</title>
        <meta
          name="description"
          content="A powerfuld set of personal personal health calculators."
        />
      </Helmet>
      <HealthIndex />
    </>
  );
};

export default HealthIndexWithHeader;
