import React, { useState } from "react";
import TitleText from "../Helpers/TitleText";
import Description from "../Helpers/Description";
import { Helmet } from "react-helmet";

const TimeDivisionCalculator = () => {
  const [bpm, setBpm] = useState(120);
  const [timeSignature, setTimeSignature] = useState("4/4");
  const [quarterNote, setQuarterNote] = useState(500);
  const [fullNote, setFullNote] = useState(2000);
  const [halfNote, setHalfNote] = useState(1000);
  const [eighthNote, setEighthNote] = useState(250);
  const [sixteenthNote, setSixteenthNote] = useState(125);
  const [measureDuration, setMeasureDuration] = useState(2000);
  const [beatDuration, setBeatDuration] = useState(500);

  const mainText = `<p>Calculate the length of notes from BPM and BPM from the length of notes. Also calculate measure duration from the time signature.</p>`;

  const calculateFromBpm = (newBpm, timeSig) => {
    const msPerBeat = 60000 / newBpm;
    const [beatsPerMeasure] = timeSig.split("/").map(Number);

    setQuarterNote(msPerBeat);
    setFullNote(msPerBeat * 4);
    setHalfNote(msPerBeat * 2);
    setEighthNote(msPerBeat / 2);
    setSixteenthNote(msPerBeat / 4);
    setBeatDuration(msPerBeat);
    setMeasureDuration(msPerBeat * beatsPerMeasure);
  };

  const handleInputChange = (field, value) => {
    const newValue = parseFloat(value);
    if (isNaN(newValue) || newValue <= 0) return;

    switch (field) {
      case "bpm":
        setBpm(newValue);
        calculateFromBpm(newValue, timeSignature);
        break;
      case "quarterNote":
        const newBpmFromQuarter = 60000 / newValue;
        setBpm(newBpmFromQuarter);
        calculateFromBpm(newBpmFromQuarter, timeSignature);
        setQuarterNote(newValue);
        break;
      case "measureDuration":
        const [beatsPerMeasure] = timeSignature.split("/").map(Number);
        const newBpmFromMeasure = (60000 * beatsPerMeasure) / newValue;
        setBpm(newBpmFromMeasure);
        calculateFromBpm(newBpmFromMeasure, timeSignature);
        setMeasureDuration(newValue);
        break;
      case "beatDuration":
        const newBpmFromBeat = 60000 / newValue;
        setBpm(newBpmFromBeat);
        calculateFromBpm(newBpmFromBeat, timeSignature);
        setBeatDuration(newValue);
        break;
      default:
        break;
    }
  };

  const handleTimeSignatureChange = (e) => {
    const newTimeSignature = e.target.value;
    setTimeSignature(newTimeSignature);
    calculateFromBpm(bpm, newTimeSignature);
  };

  return (
    <div>
      <Helmet>
        <title>Beats Per Minute Calculator</title>
        <meta
          name="description"
          content="BPM to note times calculator and note times to BPM calculator. Just enter a BPM or note value."
        />
      </Helmet>
      <div className="max-w-md mx-auto p-4 bg-base-200 rounded-lg shadow">
        <TitleText
          title={"Time Division Calculator"}
          subtitle={"BPM to notes, notes  to BPM"}
        />
        <div className="form-control mb-4">
          <label className="label">
            <span className="label-text">Beats Per Minute (BPM)</span>
          </label>
          <input
            type="number"
            value={bpm}
            onChange={(e) => handleInputChange("bpm", e.target.value)}
            className="input input-bordered"
          />
        </div>
        <div className="form-control mb-4">
          <label className="label">
            <span className="label-text">Time Signature (e.g., 4/4)</span>
          </label>
          <input
            type="text"
            value={timeSignature}
            onChange={handleTimeSignatureChange}
            className="input input-bordered"
          />
        </div>
        <div className="form-control mb-2">
          <label className="label">
            <span className="label-text">Measure Duration (ms)</span>
          </label>
          <input
            type="number"
            value={measureDuration}
            onChange={(e) =>
              handleInputChange("measureDuration", e.target.value)
            }
            className="input input-bordered"
          />
        </div>

        <div className="form-control mb-2">
          <label className="label">
            <span className="label-text">Full Note Duration (ms)</span>
          </label>
          <input
            type="number"
            value={fullNote}
            readOnly
            className="input input-bordered bg-gray-100"
          />
        </div>
        <div className="form-control mb-2">
          <label className="label">
            <span className="label-text">Half Note Duration (ms)</span>
          </label>
          <input
            type="number"
            value={halfNote}
            readOnly
            className="input input-bordered bg-gray-100"
          />
        </div>
        <div className="form-control mb-2">
          <label className="label">
            <span className="label-text">Quarter Note Duration (ms)</span>
          </label>
          <input
            type="number"
            value={quarterNote}
            onChange={(e) => handleInputChange("quarterNote", e.target.value)}
            className="input input-bordered"
          />
        </div>
        <div className="form-control mb-2">
          <label className="label">
            <span className="label-text">Eighth Note Duration (ms)</span>
          </label>
          <input
            type="number"
            value={eighthNote}
            readOnly
            className="input input-bordered bg-gray-100"
          />
        </div>
        <div className="form-control mb-2">
          <label className="label">
            <span className="label-text">Sixteenth Note Duration (ms)</span>
          </label>
          <input
            type="number"
            value={sixteenthNote}
            readOnly
            className="input input-bordered bg-gray-100"
          />
        </div>
        <div className="text-sm text-center mt-4">
          <p className="text-gray-600">
            At {bpm} BPM with a {timeSignature} time signature:
            <br /> Measure Duration: {measureDuration.toFixed(2)} ms
            <br /> Beat Duration: {beatDuration.toFixed(2)} ms
            <br /> Full Note: {fullNote.toFixed(2)} ms
            <br /> Half Note: {halfNote.toFixed(2)} ms
            <br /> Quarter Note: {quarterNote.toFixed(2)} ms
            <br /> Eighth Note: {eighthNote.toFixed(2)} ms
            <br /> Sixteenth Note: {sixteenthNote.toFixed(2)} ms
          </p>
        </div>
      </div>
      <Description title={"BPM Tempo To Note Duration"} mainText={mainText} />
    </div>
  );
};

export default TimeDivisionCalculator;
