import React from "react";
import CalculatorIndex from "./CalculatorIndex";
import { Helmet } from "react-helmet";

const HomePage = () => {
  return (
    <div>
      <Helmet>
        <title>Calcubest</title>
        <meta
          name="description"
          content="A collection of extremely useful and user-friendly calculators, including tech, health, and financial calculators"
        />
      </Helmet>
      <CalculatorIndex />
    </div>
  );
};

export default HomePage;
