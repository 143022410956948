import "./App.css";
import { Routes, Route, Outlet } from "react-router-dom";
import usePageTracking from "./components/Helpers/PageTracking";
import HomePage from "./components/HomePage";
import Header from "./components/Header";
import PutCalculator from './components/Finance/PutCalculator';
import CallCalculator from './components/Finance/CallCalculator'
import NoMatch from "./components/NoMatch";
import OklchCalc from './components/Tech/OklchCalc';
import TechIndexWithHeader from "./components/Tech/TechIndexWithHeader";
import FinanceIndexWithHeader from "./components/Finance/FinanceIndexWithHeader";
import SubnettingCalc from "./components/Tech/SubnettingCalc";
import ApyCalculator from "./components/Finance/APYCalcualtor";
import RunningPaceCalculator from "./components/Health/RunningPaceCalcualtor";
import HealthIndexWithHeader from "./components/Health/HealthIndexWithHeader";
import OtherIndex from "./components/Other/OtherIndex";
import NYCTipCalculator from "./components/Finance/NYCTipCalculator";
import ScientificCalculator from "./components/Other/ScientificCalculator";
import TDEECalculator from "./components/Health/TDEEcalc";
import HeightCalculator from "./components/Health/HeightCalc";
import PriceToWageCalculator from "./components/Finance/PriceToWageCalculator";
import Footer from "./components/Helpers/Footer";
import NYCRentCalculator from "./components/Finance/NYCRentAfforability";
import HourlyWageCalculator from "./components/Finance/HourlyWageCalculator";
import SmoothieCalculator from "./components/Health/SmoothieCalc";
import TriathlonCalculator from "./components/Health/TriathlonCalculator";
import PizzaCalculator from "./components/Finance/PizzaCostCalculator";
import TimeDivisionCalculator from "./components/Other/BPMCalculator";
import SubnetCalculator from "./components/Tech/VLSMCalculator";
import NYCTaxCalculator from "./components/Finance/NYCIncomeTax";
import EarthquakeCalculator from "./components/Other/EarthquakeCalculator";
import PtoCalculator from "./components/Finance/PTOCalculator";

function App() {
  usePageTracking()

  return (
    <div className="App ">
            <Header />

      {/* Routes nest inside one another. Nested route paths build upon
            parent route paths, and nested route elements render inside
            parent route elements. See the note about <Outlet> below. */}
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="about" element={<PutCalculator />} />

          <Route path="finance" element={<Layout />} >
            <Route index element={<FinanceIndexWithHeader />} />
            <Route path="putapy" element={<PutCalculator />} />
            <Route path="callapy" element={<CallCalculator />} />
            <Route path="apy" element={<ApyCalculator />} />
            <Route path="nycholiday" element={<NYCTipCalculator />} />
            <Route path="nycrent" element={<NYCRentCalculator />} />
            <Route path="nycincometax" element={<NYCTaxCalculator />} />
            <Route path="pricetowage" element={<PriceToWageCalculator />} />
            <Route path="hourlywage" element={<HourlyWageCalculator />} />
            <Route path="pizzacost" element={<PizzaCalculator />} />
            <Route path="pto" element={<PtoCalculator />} />
            <Route path="*" element={<NoMatch />} />
          </Route>

          <Route path="tech" element={<Layout />}>
            <Route index element={<TechIndexWithHeader />} />
            <Route path="oklch" element={<OklchCalc />} />
            <Route path="subnet" element={<SubnettingCalc />} />
            <Route path="vlsm" element={<SubnetCalculator />} />
            <Route path="*" element={<NoMatch />} />
          </Route>

          <Route path="health" element={<Layout />}>
            <Route index element={<HealthIndexWithHeader />} />
            <Route path="runningpace" element={<RunningPaceCalculator />} />
            <Route path="triathlon" element={<TriathlonCalculator />} />
            <Route path="smoothie" element={<SmoothieCalculator />} />
            <Route path="tdee" element={<TDEECalculator />} />
            <Route path="height" element={<HeightCalculator />} />
            <Route path="*" element={<NoMatch />} />
          </Route>

          <Route path="other" element={<Layout />}>
            <Route index element={<OtherIndex />} />
            <Route path="scientificcalculator" element={<ScientificCalculator />} />
            <Route path="bpmtempo" element={<TimeDivisionCalculator />} />
            <Route path="earthquake" element={<EarthquakeCalculator />} />
            <Route path="*" element={<NoMatch />} />
          </Route>

          {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>

      <Footer />
    </div>
  );
}

function Layout() {
  return (
    <div>
      {/* A "layout route" is a good place to put markup you want to
          share across all the pages on your site, like navigation. */}

      {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
                <Outlet />
    </div>
  );
}

export default App;
