import React, { useState } from "react";
import TitleText from "../Helpers/TitleText";
import Description from "../Helpers/Description";
import { Helmet } from "react-helmet";

const TAX_BRACKETS = [
  { rate: 0.1, threshold: 11000 },
  { rate: 0.12, threshold: 44725 },
  { rate: 0.22, threshold: 95375 },
  { rate: 0.24, threshold: 182100 },
  { rate: 0.32, threshold: 231250 },
  { rate: 0.35, threshold: 578125 },
  { rate: 0.37, threshold: Infinity },
];

const calculateAfterTax = (income) => {
  let remainingIncome = income;
  let taxes = 0;

  for (const bracket of TAX_BRACKETS) {
    if (remainingIncome > bracket.threshold) {
      taxes += bracket.threshold * bracket.rate;
      remainingIncome -= bracket.threshold;
    } else {
      taxes += remainingIncome * bracket.rate;
      break;
    }
  }

  return income - taxes;
};

const HourlyWageCalculator = () => {
  const [hourlyWage, setHourlyWage] = useState("");
  const [annualIncome, setAnnualIncome] = useState("");

  const handleHourlyChange = (e) => {
    const wage = parseFloat(e.target.value);
    if (isNaN(wage)) {
      setHourlyWage(e.target.value);
      return;
    }
    setHourlyWage(wage);
    setAnnualIncome(Math.round(wage * 2080));
  };

  const handleAnnualChange = (e) => {
    const income = parseFloat(e.target.value);
    if (isNaN(income)) {
      setAnnualIncome(e.target.value);
      return;
    }
    setAnnualIncome(income);
    setHourlyWage((income / 2080).toFixed(2));
  };

  const annualAfterTax = annualIncome ? calculateAfterTax(annualIncome) : 0;

  const mainText = `<p>This Wage to Income Calculator is a user-friendly application designed to seamlessly convert between hourly wages and annual income. 
</p><p>
The calculator allows users to input either their hourly wage or annual income, with the corresponding field automatically updating based on a standard assumption of 2,080 work hours per year (40 hours per week). 
</p><p>
Additionally, it includes an estimated annual income after taxes field, which dynamically calculates take-home pay using a simplified approximation of U.S. federal <a href="https://www.irs.gov/filing/federal-income-tax-rates-and-brackets" target="_blank" class="link">tax brackets</a>. 
</p><p>
This tool is perfect for employees, freelancers, or anyone interested in understanding their potential earnings in a clear and concise manner.
</p>`;

  return (
    <div>
      <Helmet>
        <title>Hourly Wage Calculator</title>
        <meta
          name="description"
          content="Calculate your annual income from your hourly wage, or your hourly wage from your annual income. Also calculate your post-tax income based on your wage"
        />
      </Helmet>
      <div className="flex flex-col items-center p-6 bg-base-200 rounded-lg shadow-lg max-w-md mx-auto mt-10">
        <TitleText
          title={"Wage to Income Calculator"}
          subtitle={"Hourly to annual wages"}
        />
        <div className="form-control">
          <label className="label">
            <span className="label-text">Hourly Wage</span>
          </label>
          <input
            type="number"
            value={hourlyWage}
            onChange={handleHourlyChange}
            placeholder="Enter hourly wage"
            className="input input-bordered w-full"
          />
        </div>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Annual Income (Pre-Tax)</span>
          </label>
          <input
            type="number"
            value={annualIncome}
            onChange={handleAnnualChange}
            placeholder="Enter annual income"
            className="input input-bordered w-full"
          />
        </div>
        <div className="form-control">
          <label className="label">
            <span className="label-text">
              Estimated Annual Income (After Tax)
            </span>
          </label>
          <input
            type="text"
            value={annualAfterTax.toFixed(2)}
            readOnly
            className="input input-bordered w-full bg-gray-100"
          />
        </div>
      </div>
      <Description title={"Wage Calculations"} mainText={mainText} />
    </div>
  );
};

export default HourlyWageCalculator;
