import React from 'react';
import TechIndex from './Tech/TechIndex';
import FinanceIndex from './Finance/FinanceIndex';
import HealthIndex from './Health/HealthIndex';
import OtherIndex from './Other/OtherIndex';

const CalculatorIndex = () => {
  return (
    <div>
        <h1 className="text-3xl font-bold">All Calculators</h1>
        <FinanceIndex />
        <HealthIndex />
        <TechIndex />
        <OtherIndex />
    </div>
  );
};

export default CalculatorIndex;
