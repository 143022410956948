import React, { useState, useMemo } from "react";
import debounce from "lodash.debounce";
import TitleText from "../Helpers/TitleText";
import Description from '../Helpers/Description';
import { Helmet } from "react-helmet";

// Constants for race distances (in kilometers)
const DISTANCES = {
  mile: 1.60934,
  "5k": 5,
  "10k": 10,
  halfMarathon: 21.0975,
  marathon: 42.195,
};

// enum
const VALUE_NAMES = Object.freeze({
  milesPerHour: "milesPerHour",
  kmPerHour: "kmPerHour",
  kmPace: "kmPace",
  milePace: "milePace",
  kilometerTime: "kilometer",
  mileTime: "mile",
  fiveKTime: "5k",
  tenKTime: "10k",
  halfMarathonTime: "half-marathon",
  marathonTime: "marathon",
  berlinTime: "Berlin Marathon",
  bostonTime: "Boston Marathon",
  chicagoTime: "Chicago Marathon",
  londonTime: "London Marathon",
  newYorkTime: "NYC Marathon",
  tokyoTime: "Tokyo Marathon",
});

const mainText = `<p>Use this running speed calculator to convert pace into race times, race times into pace, and race times from one distance to another.
</p><p>
Running pace conversion is essential for runners who want to understand their performance across different race distances and training scenarios. Athletes can use this pace calculator to translate their current race times or training paces into equivalent efforts for shorter or longer distances, which helps with goal setting, training planning, and performance prediction. 
</p><p>
By converting pace between distances like 5K, 10K, half marathon, and marathon, runners can better gauge their fitness level, adjust training intensity, and set realistic race time expectations.
</p><p>
This pace conversion tool allows runners to input a known race time or pace and quickly calculate equivalent performances for other distances, which is particularly useful for runners training for multiple race types or trying to improve their overall speed and endurance across various race distances. It's also really helpful for planning a race for a distance the runner has not ran before.
</p><p>
Finally, this running pace calculator includes estimates for the world major marathons. Each of these marathons has a different number of hills and even the weather can affect a runners finish time. This calculator is a way to estimate how much faster or slower a running might be at each marathon.
</p><p>
Note that all running pace calculators are just estimates. It's especially difficult to estimate between races whose lengths are very different. Estimating a marathon time from a 5k time is a lot more error prone than estimating a marathon time from a half-marathon time.
</p>`;

const RunningPaceCalculator = () => {
  const [milesPerHour, setMilesPerHour] = useState("");
  const [kmsPerHour, setKmsPerHour] = useState("");
  const [kmPace, setKmPace] = useState("");
  const [milePace, setMilePace] = useState("");
  const [kilometerTime, setKilometerTime] = useState("");
  const [mileTime, setMileTime] = useState("");
  const [fiveKTime, setFiveKTime] = useState("");
  const [tenKTime, setTenKTime] = useState("");
  const [halfMarathonTime, setHalfMarathonTime] = useState("");
  const [marathonTime, setMarathonTime] = useState("");
  const [marathonBostonTime, setMarathonBostonTime] = useState("");
  const [marathonChicagoTime, setMarathonChicagoTime] = useState("");
  const [marathonBerlinTime, setMarathonBerlinTime] = useState("");
  const [marathonLondonTime, setMarathonLondonTime] = useState("");
  const [marathonNewYorkTime, setMarathonNewYorkTime] = useState("");
  const [marathonTokyoTime, setMarathonTokyoTime] = useState("");
  const [informationText, setInformationText] = useState("");
  const [errorText, setErrorText] = useState("");

  const distanceFromValue = (field) => {
    switch (field) {
      case VALUE_NAMES.kmPerHour:
      case VALUE_NAMES.kmPace:
      case VALUE_NAMES.kilometerTime:
        return 1;
      case VALUE_NAMES.milesPerHour:
      case VALUE_NAMES.milePace:
      case VALUE_NAMES.mileTime:
        return DISTANCES.mile;
      case VALUE_NAMES.fiveKTime:
        return DISTANCES["5k"];
      case VALUE_NAMES.tenKTime:
        return DISTANCES["10k"];
      case VALUE_NAMES.halfMarathonTime:
        return DISTANCES.halfMarathon;
      default:
        return DISTANCES.marathon;
    }
  };

  function riegelEstimate(knownTime, knownDistance, targetDistance) {
    let b = 1.06;

    if (
      targetDistance === DISTANCES.halfMarathon &&
      knownDistance < DISTANCES["10k"]
    ) {
      b = 1.07;
    }

    if (
      knownDistance === DISTANCES.halfMarathon &&
      targetDistance < DISTANCES["10k"]
    ) {
      b = 1.07;
    }

    if (
      targetDistance === DISTANCES.marathon &&
      knownDistance < DISTANCES.halfMarathon
    ) {
      b = 1.08;
    }

    if (
      knownDistance === DISTANCES.marathon &&
      targetDistance < DISTANCES.halfMarathon
    ) {
      b = 1.08;
    }

    // https://www.theguardian.com/lifeandstyle/the-running-blog/2018/feb/15/an-updated-formula-for-marathon-running-success
    if (
      knownDistance === DISTANCES.halfMarathon &&
      targetDistance === DISTANCES.marathon
    ) {
      b = 1.15;
    }

    if (
      targetDistance === DISTANCES.halfMarathon &&
      knownDistance === DISTANCES.marathon
    ) {
      b = 1.15;
    }

    // Calculate predicted time for the target distance
    const predictedTime =
      knownTime * Math.pow(targetDistance / knownDistance, b);

    return predictedTime;
  }

  // Convert pace between any two distances
  const calculateEquivalentTime = (time, fromDistance, toDistance) => {
    // no adjustment if the use modified non-distance formulas
    if (
      fromDistance === VALUE_NAMES.milesPerHour ||
      fromDistance === VALUE_NAMES.KmPerHour
    ) {
      return (
        (time * distanceFromValue(toDistance)) / distanceFromValue(fromDistance)
      );
    }

    if (
      fromDistance === VALUE_NAMES.milePace ||
      fromDistance === VALUE_NAMES.kmPace
    ) {
      return (
        (time * distanceFromValue(toDistance)) / distanceFromValue(fromDistance)
      );
    }

    if (fromDistance === toDistance) {
      return time;
    }

    const riegleTime = riegelEstimate(
      time,
      distanceFromValue(fromDistance),
      distanceFromValue(toDistance)
    );

    return (riegleTime * modifierFor(toDistance)) / modifierFor(fromDistance);
  };

  const updateAllFieldsFromKmHr = useMemo(
    () =>
      debounce((sourceField, kmPerHr) => {
        try {
          const milePerHr = kmPerHr / DISTANCES.mile;

          if (sourceField !== VALUE_NAMES.kmPerHour) {
            setKmsPerHour(kmPerHr.toFixed(2));
          }

          if (sourceField !== VALUE_NAMES.milesPerHour) {
            setMilesPerHour(milePerHr.toFixed(2));
          }

          const secondsPerKm = 3600 / kmPerHr;

          if (sourceField !== VALUE_NAMES.kmPace) {
            setKmPace(secondsToString(secondsPerKm));
          }

          if (sourceField !== VALUE_NAMES.milePace) {
            const secondsPerMile = secondsPerKm * DISTANCES.mile;
            setMilePace(secondsToString(secondsPerMile));
          }

          let sourceDistance = distanceFromValue(sourceField);
          const baseTime = secondsPerKm * sourceDistance;

          if (sourceField !== VALUE_NAMES.kilometerTime) {
            const predictedTime = calculateEquivalentTime(
              baseTime,
              sourceField,
              VALUE_NAMES.kilometerTime
            );
            setKilometerTime(secondsToString(predictedTime));
          }

          if (sourceField !== VALUE_NAMES.mileTime) {
            const predictedTime = calculateEquivalentTime(
              baseTime,
              sourceField,
              VALUE_NAMES.mileTime
            );
            setMileTime(secondsToString(predictedTime));
          }

          if (sourceField !== VALUE_NAMES.fiveKTime) {
            const distancePace = calculateEquivalentTime(
              baseTime,
              sourceField,
              VALUE_NAMES.fiveKTime
            );
            const predictedTime = distancePace; // * DISTANCES["5k"] / DISTANCES.mile;
            setFiveKTime(secondsToString(predictedTime));
          }

          if (sourceField !== VALUE_NAMES.tenKTime) {
            const predictedTime = calculateEquivalentTime(
              baseTime,
              sourceField,
              VALUE_NAMES.tenKTime
            );
            setTenKTime(secondsToString(predictedTime));
          }

          if (sourceField !== VALUE_NAMES.halfMarathonTime) {
            const predictedTime = calculateEquivalentTime(
              baseTime,
              sourceField,
              VALUE_NAMES.halfMarathonTime
            );
            setHalfMarathonTime(secondsToString(predictedTime));
          }

          if (sourceField !== VALUE_NAMES.marathonTime) {
            const predictedTime = calculateEquivalentTime(
              baseTime,
              sourceField,
              VALUE_NAMES.marathonTime
            );
            setMarathonTime(secondsToString(predictedTime));
          }

          if (sourceField !== VALUE_NAMES.berlinTime) {
            const predictedTime = calculateEquivalentTime(
              baseTime,
              sourceField,
              VALUE_NAMES.berlinTime
            );
            setMarathonBerlinTime(secondsToString(predictedTime));
          }

          if (sourceField !== VALUE_NAMES.bostonTime) {
            const predictedTime = calculateEquivalentTime(
              baseTime,
              sourceField,
              VALUE_NAMES.bostonTime
            );
            setMarathonBostonTime(secondsToString(predictedTime));
          }

          if (sourceField !== VALUE_NAMES.chicagoTime) {
            const predictedTime = calculateEquivalentTime(
              baseTime,
              sourceField,
              VALUE_NAMES.chicagoTime
            );
            setMarathonChicagoTime(secondsToString(predictedTime));
          }

          if (sourceField !== VALUE_NAMES.tokyoTime) {
            const predictedTime = calculateEquivalentTime(
              baseTime,
              sourceField,
              VALUE_NAMES.tokyoTime
            );
            setMarathonTokyoTime(secondsToString(predictedTime));
          }

          if (sourceField !== VALUE_NAMES.londonTime) {
            const predictedTime = calculateEquivalentTime(
              baseTime,
              sourceField,
              VALUE_NAMES.londonTime
            );
            setMarathonLondonTime(secondsToString(predictedTime));
          }

          if (sourceField !== VALUE_NAMES.newYorkTime) {
            const predictedTime = calculateEquivalentTime(
              baseTime,
              sourceField,
              VALUE_NAMES.newYorkTime
            );
            setMarathonNewYorkTime(secondsToString(predictedTime));
          }
        } catch (error) {
          setErrorText("Error updating fields: " + error.message);
        }
      }, 500),
    []
  );

  const isTimeValid = (timeNumber) => {
    return !isNaN(timeNumber) && timeNumber >= 0;
  };

  // Convert "2:23:45" to seconds
  const stringToSeconds = (timeString) => {
    const timeNums = timeString.split(":");
    let hours = 0,
      mins = 0,
      secs = 0;

    if (timeNums.length === 3) {
      [hours, mins, secs] = timeNums.map(Number);
    } else if (timeNums.length === 2) {
      [mins, secs] = timeNums.map(Number);
    } else {
      throw new Error("Please use HH:MM:SS format.");
    }

    if (
      !isTimeValid(hours) ||
      !isTimeValid(mins) ||
      !isTimeValid(secs) ||
      (hours > 0 && mins > 59) ||
      (mins > 0 && secs > 59)
    ) {
      throw new Error("Invalid time format");
    }

    return secs + mins * 60 + hours * 3600;
  };

  // Convert seconds to "2:23:45" format
  const secondsToString = (totalSeconds) => {
    let hours = Math.floor(totalSeconds / 3600);
    let minutes = Math.floor((totalSeconds % 3600) / 60);
    let seconds = Math.round(totalSeconds % 60);

    // Handle seconds rounded to 60
    if (seconds === 60) {
      seconds = 0;
      minutes += 1;
      if (minutes === 60) {
        minutes = 0;
        hours += 1;
      }
    }
    const pad = (num) => String(num).padStart(2, "0");
    return hours > 0
      ? `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`
      : `${pad(minutes)}:${pad(seconds)}`;
  };

  const calculateKPH = (seconds, kilometers) => {
    const hours = seconds / 3600;
    return kilometers / hours;
  };

  const handlePaceChange = (e, field, distance = null) => {
    const value = e.target.value;
    setErrorText("");

    switch (field) {
      case VALUE_NAMES.milesPerHour:
        setMilesPerHour(value);
        const milesFloat = parseFloat(value);
        if (!isNaN(milesFloat) && milesFloat > 0) {
          const kph = milesFloat * DISTANCES.mile;
          updateAllFieldsFromKmHr(field, kph);
          setInformationText("Values based on a " + value + "mph pace");
        }
        break;

      case VALUE_NAMES.kmPerHour:
        setKmsPerHour(value);
        const kmsFloat = parseFloat(value);
        if (!isNaN(kmsFloat) && kmsFloat > 0) {
          updateAllFieldsFromKmHr(field, kmsFloat);
          setInformationText("Values based on a " + value + "kph pace");
        }
        break;

      case VALUE_NAMES.kmPace:
        setKmPace(value);
        try {
          const totalSeconds = stringToSeconds(value);
          const hours = totalSeconds / 3600;
          updateAllFieldsFromKmHr(field, 1 / hours);
          setInformationText("Values based on a " + value + "km pace");
        } catch (error) {
          setErrorText("Error parsing km pace: " + error.message);
        }
        break;

      case VALUE_NAMES.milePace:
        setMilePace(value);
        try {
          const totalSeconds = stringToSeconds(value);
          const hours = totalSeconds / 3600;
          updateAllFieldsFromKmHr(field, DISTANCES.mile / hours);
          setInformationText("Values based on a " + value + " mile pace");
        } catch (error) {
          setErrorText("Error parsing mile pace: " + error.message);
        }
        break;

      default:
        // Handle time-based inputs
        try {
          const seconds = stringToSeconds(value);
          if (distance) {
            const kmPerHour = calculateKPH(seconds, distance);
            updateAllFieldsFromKmHr(field, kmPerHour);
            setInformationText(
              "Values based on a " + value + " " + field + " time"
            );
          }
        } catch (error) {
          setErrorText("Error parsing time: " + error.message);
        }

        // Update the specific state based on field
        switch (field) {
          case VALUE_NAMES.kilometerTime:
            setKilometerTime(value);
            break;
          case VALUE_NAMES.mileTime:
            setMileTime(value);
            break;
          case VALUE_NAMES.kmPace:
            setKmPace(value);
            break;
          case VALUE_NAMES.milePace:
            setMilePace(value);
            break;
          case VALUE_NAMES.fiveKTime:
            setFiveKTime(value);
            break;
          case VALUE_NAMES.tenKTime:
            setTenKTime(value);
            break;
          case VALUE_NAMES.halfMarathonTime:
            setHalfMarathonTime(value);
            break;
          case VALUE_NAMES.marathonTime:
            setMarathonTime(value);
            break;
          case VALUE_NAMES.bostonTime:
            setMarathonBostonTime(value);
            break;
          case VALUE_NAMES.chicagoTime:
            setMarathonChicagoTime(value);
            break;
          case VALUE_NAMES.berlinTime:
            setMarathonBerlinTime(value);
            break;
          case VALUE_NAMES.londonTime:
            setMarathonLondonTime(value);
            break;
          case VALUE_NAMES.newYorkTime:
            setMarathonNewYorkTime(value);
            break;
          case VALUE_NAMES.tokyoTime:
            setMarathonTokyoTime(value);
            break;
          default:
            break;
        }
    }
  };

  const modifierFor = (field) => {
    switch (field) {
      case VALUE_NAMES.bostonTime:
        return 1.0043;
      case VALUE_NAMES.chicagoTime:
        return 1.0028;
      case VALUE_NAMES.berlinTime:
        return 1.0133;
      case VALUE_NAMES.londonTime:
        return 1.0072;
      case VALUE_NAMES.newYorkTime:
        return 1.0194;
      case VALUE_NAMES.tokyoTime:
        return 1.0009;
      default:
        return 1;
    }
  };

  return (
    <div>
      <Helmet>
              <title>Running Pace Calculator</title>
              <meta
                name="description"
                content="The most comprehensive running pace calculator! Calculate your running pace for different distances, km to miles, and even the finish time of world major marathons."
              />
            </Helmet>
    <div className="p-6 max-w-lg mx-auto space-y-4">
      <TitleText title={"Running Pace Calculator"} subtitle={"Running estimates based on any speed/distance."} />

      {informationText &&
      <div role="alert" className="alert">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          className="stroke-info h-6 w-6 shrink-0"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          ></path>
        </svg>
        <span>{informationText}</span>
      </div>
      }

      <p className="text-red-500 mt-2">{errorText}</p>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="form-control">
          <label className="label">
            <span className="label-text">Miles Per Hour</span>
          </label>
          <input
            type="number"
            className="input input-bordered w-full"
            value={milesPerHour}
            onChange={(e) => handlePaceChange(e, VALUE_NAMES.milesPerHour)}
            placeholder="0.00"
            step="0.01"
            min="0"
          />
        </div>

        <div className="form-control">
          <label className="label">
            <span className="label-text">Kilometers Per Hour</span>
          </label>
          <input
            type="number"
            className="input input-bordered w-full"
            value={kmsPerHour}
            onChange={(e) => handlePaceChange(e, VALUE_NAMES.kmPerHour)}
            placeholder="0.00"
            step="0.01"
            min="0"
          />
        </div>

        <div className="form-control">
          <label className="label">
            <span className="label-text">Kilometer Pace</span>
          </label>
          <input
            type="text"
            className="input input-bordered w-full"
            value={kmPace}
            onChange={(e) => handlePaceChange(e, VALUE_NAMES.kmPace)}
            placeholder="MM:SS"
          />
        </div>

        <div className="form-control">
          <label className="label">
            <span className="label-text">Mile Pace</span>
          </label>
          <input
            type="text"
            className="input input-bordered w-full"
            value={milePace}
            onChange={(e) => handlePaceChange(e, VALUE_NAMES.milePace)}
            placeholder="MM:SS"
          />
        </div>
      </div>

      <h3 className="text-lg font-semibold text-gray-700">Race Distances</h3>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="form-control">
          <label className="label">
            <span className="label-text">1K Time</span>
          </label>
          <input
            type="text"
            className="input input-bordered w-full"
            value={kilometerTime}
            onChange={(e) => handlePaceChange(e, VALUE_NAMES.kilometerTime, 1)}
            placeholder="MM:SS"
          />
        </div>

        <div className="form-control">
          <label className="label">
            <span className="label-text">1 Mile Time</span>
          </label>
          <input
            type="text"
            className="input input-bordered w-full"
            value={mileTime}
            onChange={(e) =>
              handlePaceChange(e, VALUE_NAMES.mileTime, DISTANCES.mile)
            }
            placeholder="MM:SS"
          />
        </div>

        <div className="form-control">
          <label className="label">
            <span className="label-text">5K Time</span>
          </label>
          <input
            type="text"
            className="input input-bordered w-full"
            value={fiveKTime}
            onChange={(e) =>
              handlePaceChange(e, VALUE_NAMES.fiveKTime, DISTANCES["5k"])
            }
            placeholder="HH:MM:SS"
          />
        </div>

        <div className="form-control">
          <label className="label">
            <span className="label-text">10K Time</span>
          </label>
          <input
            type="text"
            className="input input-bordered w-full"
            value={tenKTime}
            onChange={(e) =>
              handlePaceChange(e, VALUE_NAMES.tenKTime, DISTANCES["10k"])
            }
            placeholder="HH:MM:SS"
          />
        </div>

        <div className="form-control">
          <label className="label">
            <span className="label-text">Half Marathon Time</span>
          </label>
          <input
            type="text"
            className="input input-bordered w-full"
            value={halfMarathonTime}
            onChange={(e) =>
              handlePaceChange(
                e,
                VALUE_NAMES.halfMarathonTime,
                DISTANCES.halfMarathon
              )
            }
            placeholder="HH:MM:SS"
          />
        </div>

        <div className="form-control">
          <label className="label">
            <span className="label-text">Flat Marathon Time</span>
          </label>
          <input
            type="text"
            className="input input-bordered w-full"
            value={marathonTime}
            onChange={(e) =>
              handlePaceChange(e, VALUE_NAMES.marathonTime, DISTANCES.marathon)
            }
            placeholder="HH:MM:SS"
          />
        </div>
      </div>

      <h3 className="text-lg font-semibold text-gray-700">
        World Marathon Majors
      </h3>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="form-control">
          <label className="label">
            <span className="label-text">Berlin Marathon Time</span>
          </label>
          <input
            type="text"
            className="input input-bordered w-full"
            value={marathonBerlinTime}
            onChange={(e) =>
              handlePaceChange(
                e,
                VALUE_NAMES.berlinMarathon,
                DISTANCES.marathon
              )
            }
            placeholder="HH:MM:SS"
          />
        </div>

        <div className="form-control">
          <label className="label">
            <span className="label-text">Boston Marathon Time</span>
          </label>
          <input
            type="text"
            className="input input-bordered w-full"
            value={marathonBostonTime}
            onChange={(e) =>
              handlePaceChange(e, VALUE_NAMES.bostonTime, DISTANCES.marathon)
            }
            placeholder="HH:MM:SS"
          />
        </div>

        <div className="form-control">
          <label className="label">
            <span className="label-text">Chicago Marathon Time</span>
          </label>
          <input
            type="text"
            className="input input-bordered w-full"
            value={marathonChicagoTime}
            onChange={(e) =>
              handlePaceChange(e, VALUE_NAMES.chicagoTime, DISTANCES.marathon)
            }
            placeholder="HH:MM:SS"
          />
        </div>

        <div className="form-control">
          <label className="label">
            <span className="label-text">London Marathon Time</span>
          </label>
          <input
            type="text"
            className="input input-bordered w-full"
            value={marathonLondonTime}
            onChange={(e) =>
              handlePaceChange(e, VALUE_NAMES.londonTime, DISTANCES.marathon)
            }
            placeholder="HH:MM:SS"
          />
        </div>

        <div className="form-control">
          <label className="label">
            <span className="label-text">New York City Marathon Time</span>
          </label>
          <input
            type="text"
            className="input input-bordered w-full"
            value={marathonNewYorkTime}
            onChange={(e) =>
              handlePaceChange(e, VALUE_NAMES.newYorkTime, DISTANCES.marathon)
            }
            placeholder="HH:MM:SS"
          />
        </div>

        <div className="form-control">
          <label className="label">
            <span className="label-text">Tokyo Marathon Time</span>
          </label>
          <input
            type="text"
            className="input input-bordered w-full"
            value={marathonTokyoTime}
            onChange={(e) =>
              handlePaceChange(e, VALUE_NAMES.tokyoTime, DISTANCES.marathon)
            }
            placeholder="HH:MM:SS"
          />
        </div>
      </div>
    </div>
    <Description title={"Running Pace Conversions"} mainText={mainText} />
    </div>
  );
};

export default RunningPaceCalculator;
