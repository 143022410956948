import React, { useState } from 'react';
import TitleText from '../Helpers/TitleText';
import Description from '../Helpers/Description';
import { Helmet } from 'react-helmet';

const InputField = ({ label, type, value, onChange, placeholder }) => (
    <div>
      <label className="block text-sm font-medium text-gray-700 mb-2">{label}</label>
      <input
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
      />
    </div>
  );

const EarthquakeCalculator = () => {
  const [formula, setFormula] = useState('energy');
  const [input1, setInput1] = useState('');
  //, setInput2] = useState('');
  const [compareM1, setCompareM1] = useState('');
  const [compareM2, setCompareM2] = useState('');
  const [result, setResult] = useState('');

  const formatEnergy = (joules) => {
    if (joules >= 1e24) return `${(joules / 1e24).toFixed(2)} YJ (yottajoules)`;
    if (joules >= 1e21) return `${(joules / 1e21).toFixed(2)} ZJ (zettajoules)`;
    if (joules >= 1e18) return `${(joules / 1e18).toFixed(2)} EJ (exajoules)`;
    if (joules >= 1e15) return `${(joules / 1e15).toFixed(2)} PJ (petajoules)`;
    if (joules >= 1e12) return `${(joules / 1e12).toFixed(2)} TJ (terajoules)`;
    return `${joules.toExponential(2)} J`;
  };

  const mainText = `<p>This is a useful set of easy-to-use earthquake calculators.</p>
<p>You can calculate the energy released by an earthquake based on the Richter Scale value. This is calculated in joules, TNT, and the total daily energy usage of the city of San Francisco, California (around 55 million Kilowatthours).</p>
<p>You can compare how much more powerful one earthquake was than another earthquake by entering two Richter Scale values.</p>
`;

  const formatTNT = (joules) => {
    const tntEquivalent = joules / 4.184e9;
    if (tntEquivalent >= 1000) {
        const tntEquivalentKt = joules / 4.184e12;
        if (tntEquivalentKt >= 1000) {
            return `${(tntEquivalentKt / 1000).toFixed(2)} megatons of TNT`;
          }
          return `${tntEquivalentKt.toFixed(2)} kilotons of TNT`;
    }
    return `${tntEquivalent.toFixed(2)} tons of TNT`;
  };

  const calculateResult = () => {    
    switch(formula) {
      case 'energy':
        const magnitude = parseFloat(input1);
        const energyJoules = Math.pow(10, (4.8 + 1.5 * magnitude));

        // 55 million kWh is an estimate of SF's daily energy usage
        const sanFranciscoEquivalent = energyJoules / 1.98e14;

        setResult(
          `Energy Release:\n${formatEnergy(energyJoules)}\nEquivalent to ${formatTNT(energyJoules)}\nEquivalent to ${sanFranciscoEquivalent.toFixed(2)} daily energy usage of San Francisco (55 million kWh).`
        );
        break;

      case 'compare':
        const m1 = parseFloat(compareM1);
        const m2 = parseFloat(compareM2);
        const energyRatio = Math.pow(10, 1.5 * Math.abs(m1 - m2));
        const largerMag = Math.max(m1, m2);
        const smallerMag = Math.min(m1, m2);
        setResult(`A magnitude ${largerMag} earthquake releases ${energyRatio.toFixed(1)} times more energy than a magnitude ${smallerMag} earthquake`);
        break;
    }
  };

  const Label = ({ children }) => (
    <label className="block text-sm font-medium text-gray-700 mb-2">
      {children}
    </label>
  );

  const Select = ({ value, onChange, options }) => (
    <select
      value={value}
      className="select select-bordered w-full"
      onChange={(e) => onChange(e.target.value)}
    >
      {options.map(opt => (
        <option key={opt.value} value={opt.value}>{opt.label}</option>
      ))}
    </select>
  );

  const formulaOptions = [
    { value: 'energy', label: 'Energy Release' },
    { value: 'compare', label: 'Compare Magnitudes' }
  ];

  const handleFormula = (value) => {
    setFormula(value);
    setInput1("");
    //setInput2("");
    setCompareM1("");
    setCompareM2("");
  }

  return (
    <div>
      <Helmet>
        <title>Earthquake Calculators</title>
        <meta
          name="description"
          content="Usefule earthquake calculators. The energy released by an earthquake, earthquake conversions, etc.."
        />
      </Helmet>
        <div className="flex flex-col items-center p-6 bg-base-200 rounded-lg shadow-lg max-w-md mx-auto mt-10">
        <div className="space-y-6">
        <TitleText title={"Earthquake Calculators"} subtitle={"Useful Earthquake Calculations"} />
        
        <div className="space-y-4">
          <div>
            <Label>Type of Earthquake Calculation</Label>
            <Select
              value={formula}
              onChange={handleFormula}
              options={formulaOptions}
            />
          </div>
          
          {formula === 'energy' && (
        <InputField
          label="Richter Magnitude"
          type="number"
          value={input1}
          onChange={(e) => setInput1(e.target.value)}
          placeholder="Enter magnitude"
        />
      )}

      {formula === 'compare' && (
        <>
          <InputField
            label="First Magnitude"
            type="number"
            value={compareM1}
            onChange={(e) => setCompareM1(e.target.value)}
            placeholder="Enter first magnitude"
          />
          <InputField
            label="Second Magnitude"
            type="number"
            value={compareM2}
            onChange={(e) => setCompareM2(e.target.value)}
            placeholder="Enter second magnitude"
          />
        </>
      )}
          
          <button
            onClick={calculateResult}
            className="btn btn-primary"          >
            Calculate
          </button>
          
          {result && (
            <div className="mt-6 p-6 bg-blue-100 rounded-lg shadow-md">
              <p className="text-center text-xl font-medium whitespace-pre-line">{result}</p>
            </div>
          )}
        </div>
      </div>
    </div>
    <Description title={"Earthquake Calculations"} mainText={mainText} />
    </div>
  );
};

export default EarthquakeCalculator;
