import React, { useState } from "react";
import TitleText from "../Helpers/TitleText";
import Description from "../Helpers/Description";
import { Helmet } from "react-helmet";

const NYCRentCalculator = () => {
  const [values, setValues] = useState({
    monthlyRent: "",
    annualIncome: "",
  });

  const mainText = `<p>In New York City your gross income must be at least 40 times your monthly rent amount.</p>
  <p>Enter a rent amount to see how much income you need to afford that unit.</p>
  <p>Enter your income to see the max monthly NYC rent you can affort.</p>`;

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (!/^\d*\.?\d*$/.test(value)) return; // Allow only numeric input

    if (name === "monthlyRent") {
      const monthlyRent = parseFloat(value) || 0;
      const annualIncome = monthlyRent * 40;
      setValues({
        monthlyRent: value,
        annualIncome: annualIncome ? annualIncome.toFixed(2) : "",
      });
    } else if (name === "annualIncome") {
      const annualIncome = parseFloat(value) || 0;
      const monthlyRent = annualIncome / 40;
      setValues({
        monthlyRent: monthlyRent ? monthlyRent.toFixed(2) : "",
        annualIncome: value,
      });
    }
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-base-200 rounded-lg shadow-lg">
            <Helmet>
        <title>NYC Rent Affordability Calculator</title>
        <meta
          name="description"
          content="Find the minimum 40x amount of income needed to rent in New York City. You can input either your income or the rent amount."
        />
      </Helmet>
      <TitleText title={"NYC Rent Affordability"} subtitle={"40x Rule Calculator"} />
      <form className="space-y-4">
        <div>
          <label className="label">
            <span className="label-text">Monthly Rent</span>
          </label>
          <input
            type="text"
            name="monthlyRent"
            value={values.monthlyRent}
            onChange={handleChange}
            className="input input-bordered w-full"
            placeholder="Enter monthly rent"
          />
        </div>
        <div>
          <label className="label">
            <span className="label-text">Minimum Annual Income</span>
          </label>
          <input
            type="text"
            name="annualIncome"
            value={values.annualIncome}
            onChange={handleChange}
            className="input input-bordered w-full"
            placeholder="Enter annual income"
          />
        </div>
      </form>
      <Description title={"Rent in New York City"} mainText={mainText} />
    </div>
  );
};

export default NYCRentCalculator;
