import React, { useState, useEffect } from 'react';
import TitleText from "../Helpers/TitleText";
import Description from "../Helpers/Description";
import { Helmet } from 'react-helmet';

const SubnetCalculator = () => {
  const [ipAddress, setIpAddress] = useState('192.168.1.0/24');
  const [subnets, setSubnets] = useState([{ hosts: '' }]);
  const [results, setResults] = useState(null);

  const handleAddSubnet = () => {
    setSubnets([...subnets, { hosts: '' }]);
  };

  const handleRemoveSubnet = (index) => {
    setSubnets(subnets.filter((_, i) => i !== index));
  };

  const handleSubnetChange = (index, value) => {
    const updatedSubnets = [...subnets];
    updatedSubnets[index].hosts = value;
    setSubnets(updatedSubnets);
  };

  const mainText = `<p>Use this VLSM subnetting calculator to breakup a larger network into smaller subnets. Figure out how many hosts and IP addresses each of your subnets gains and needs when you create a subnet of a certain size.
</p><p>
For information about a specific subnet you should check out our <a href="/tech/subnet/" class="link">Subnet Calculator</a></p>`;

  // TODO: remove useEffect. 
  useEffect(() => {
      const [network, mask] = ipAddress.split('/');
      const subnetBits = parseInt(mask, 10);

      if (!network || isNaN(subnetBits) || subnetBits < 0 || subnetBits > 32) {
        setResults({ error: 'Enter a valid IP address range and netmask.' });
        return;
      }

      const ipSegments = network.split('.').map(Number);
      if (ipSegments.length !== 4 || ipSegments.some(seg => seg < 0 || seg > 255)) {
        setResults({ error: 'Enter a valid IPv4 address range and netmask.' });
        return;
      }

      const totalHosts = Math.pow(2, 32 - subnetBits) - 2;
      const requiredHosts = subnets.reduce((sum, subnet) => sum + parseInt(subnet.hosts || 0, 10), 0);

      if (requiredHosts > totalHosts) {
        setResults({ error: 'Not enough hosts available for the requested subnetting. You want ' + requiredHosts + ' hosts. You can only have up to ' + totalHosts + ' hosts' });
        return;
      }

      const sortedSubnets = subnets
        .filter((s) => s.hosts > 0) 
        .map((subnet, index) => ({ ...subnet, index, hosts: parseInt(subnet.hosts, 10) }))
        .sort((a, b) => b.hosts - a.hosts);

      let currentAddressBinary = ipSegments
        .map(seg => seg.toString(2).padStart(8, '0'))
        .join('')
        .substring(0, subnetBits)
        .padEnd(32, '0');

      let totals = {
        usableHosts: 0,
        usedIPs: 0,
        ogIPs: Math.pow(2, 32 - subnetBits)
      }
      const subnetResults = sortedSubnets.map(({ hosts, index }) => {
        const neededBits = Math.ceil(Math.log2(hosts + 2));
        const newMask = 32 - neededBits;

        const subnetBinary = currentAddressBinary.substring(0, newMask).padEnd(32, '0');
        const subnetAddress = subnetBinary.match(/.{1,8}/g).map(bin => parseInt(bin, 2)).join('.');

        const broadcastBinary = subnetBinary.substring(0, newMask).padEnd(32, '1');
        const broadcastAddress = broadcastBinary.match(/.{1,8}/g).map(bin => parseInt(bin, 2)).join('.');

        currentAddressBinary = (parseInt(broadcastBinary, 2) + 1).toString(2).padStart(32, '0');

        totals.usableHosts += Math.pow(2, neededBits) - 2;
        totals.usedIPs += Math.pow(2, neededBits)
        return {
          subnetName: `Subnet ${index + 1}`,
          subnetMask: newMask,
          subnetAddress,
          broadcastAddress,
          usableHosts: Math.pow(2, neededBits) - 2,
        };
      });

      console.log("AAAA" + Math.pow(2, 32 - subnetBits))
      if (totals.usedIPs > Math.pow(2, 32 - subnetBits)) {
        setResults({ error: 'Not enough IPs available for the requested subnetting. You are trying to use ' + totals.usedIPs + ' IPs. You have at most ' + (Math.pow(2, 32 - subnetBits)) + 'IPs'});
        return;
      }

      setResults({
        totals: totals,
        subnetResults: subnetResults
  });
  }, [ipAddress, subnets]);

  return (
    <div>
      <Helmet>
              <title>Subnetting Calculator</title>
              <meta
                name="description"
                content="Powerful VLSM calculator to divide a larger subnet into smaller subnets."
              />
            </Helmet>
    <div className="p-6 bg-base-100">
        <div className="max-w-lg max-w-lg mx-auto">
            <TitleText title={"CIDR/VLSM Subnetting Calculator"} subtitle={"Divide a network into subnets"} />
      <div className="form-control w-full mb-4">
        <label className="label">
          <span className="label-text">IP Address with CIDR (e.g., 192.168.1.0/24):</span>
        </label>
        <input
          type="text"
          className="input input-bordered w-full"
          placeholder="Enter IP Address"
          value={ipAddress}
          onChange={(e) => setIpAddress(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <h2 className="text-lg font-bold mb-2">Subnets (hosts needed)</h2>
        {subnets.map((subnet, index) => (
          <div key={index} className="flex items-center mb-2">
            <span className="mr-2">Subnet {index + 1}:</span>
            <input
              type="number"
              className="input input-bordered w-full mr-2"
              placeholder="Hosts needed"
              value={subnet.hosts}
              onChange={(e) => handleSubnetChange(index, e.target.value)}
            />
            <button className="btn btn-error btn-sm" onClick={() => handleRemoveSubnet(index)}>
              Remove
            </button>
          </div>
        ))}
        <button className="btn btn-secondary mt-2" onClick={handleAddSubnet}>
          Add Subnet
        </button>
        </div>
      </div>
      {results && (
        <div className="mt-6 p-4 bg-base-200 rounded">
          {results.error ? (
            <p className="text-error">{results.error}</p>
          ) : (
            <>
              <h3 className="text-lg font-bold mb-2">Subnetworks:</h3>
              <table className="table table-compact w-full">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Subnet Mask</th>
                    <th>Subnet Address</th>
                    <th>Broadcast Address</th>
                    <th>Usable Hosts</th>
                  </tr>
                </thead>
                <tbody>
                  {results.subnetResults.map((subnet, index) => (
                    <tr key={index}>
                      <td>{subnet.subnetName}</td>
                      <td>/{subnet.subnetMask}</td>
                      <td>{subnet.subnetAddress}</td>
                      <td>{subnet.broadcastAddress}</td>
                      <td>{subnet.usableHosts}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {results.subnetResults.length > 0 && (<>
                <div><strong>Total IPs used:</strong> {results.totals.usedIPs}</div>
                <div><strong>Total Usable Hosts:</strong> {results.totals.usableHosts}</div>
                <div>If left undivided, {ipAddress} has {results.totals.ogIPs} total IPs, with {results.totals.ogIPs - 2} usable hosts.</div>
                </>)}
              <div className="italic text-sm">Subnets are ordered by size from largest to smallest. You should always start subnetting with the largest subnet.</div>
            </>
          )}
        </div>
      )}
    </div>
        <Description title={"Subnetting A Network"} mainText={mainText} />
    </div>
  );
};

export default SubnetCalculator;
