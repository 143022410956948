import React, { useState, useMemo, useEffect } from "react";
import Color from "colorjs.io";
import debounce from "lodash.debounce";
import TitleText from "../Helpers/TitleText";
import Description from "../Helpers/Description";
import { Helmet } from "react-helmet";

function OklchCalc() {
  const [lightness, setLightness] = useState(0.5); // Range 0 to 1
  const [chroma, setChroma] = useState(0.1); // Range 0 to 1
  const [hue, setHue] = useState(0); // Range 0 to 360 (degrees)

  const [hex, setHex] = useState("#ff0000");
  const [rgb, setRgb] = useState("rgb(255, 0, 0)");
  const [hsl, setHsl] = useState("hsl(0, 100%, 50%)");
  const [oklch, setOklch] = useState("oklch(0.5 0.1 0)");

  // States for error messages
  const [hexError, setHexError] = useState("");
  const [rgbError, setRgbError] = useState("");
  const [hslError, setHslError] = useState("");
  const [oklchError, setOklchError] = useState("");

  const mainText = `Use this OKLCH picker to find the exact color you are looking for. You can also convert between RGB, HEX, and HSL to get a OKLCH color.
<p />
OKLCH is a color model designed to address perceptual uniformity issues in traditional color spaces like RGB and HSL. Unlike these older models, OKLCH provides more consistent color perception across different lightness levels and hues, meaning colors look more natural and predictable to human vision. It's particularly useful for web design, digital interfaces, and any application where precise color representation matters.
<p />
The primary advantages of OKLCH include better readability, improved color mixing, and more intuitive color selection. Web developers and designers benefit from its perceptually uniform nature, which allows for more predictable color adjustments and ensures that color changes appear smoother and more consistent. A OKLCH color picker and converter becomes essential because it helps professionals easily translate between different color spaces, enabling more accurate color manipulation and ensuring visual harmony across digital projects.`;

  // Generate the current color based on OKLCH values
  const getCurrentColor = () => {
    return `oklch(${lightness} ${chroma} ${hue}deg)`;
  };

  const currentColor = getCurrentColor();

  // Calculate gradient for each slider background based on the other sliders' values
  const getLightnessGradient = () => {
    return `linear-gradient(to right, 
      oklch(0 ${chroma} ${hue}deg), 
      oklch(1 ${chroma} ${hue}deg))`;
  };

  const getChromaGradient = () => {
    return `linear-gradient(to right, 
      oklch(${lightness} 0 ${hue}deg), 
      oklch(${lightness} 1 ${hue}deg))`;
  };

  const getHueGradient = () => {
    return `linear-gradient(to right,
    oklch(${lightness} ${chroma} 0deg),
    oklch(${lightness} ${chroma} 30deg),
    oklch(${lightness} ${chroma} 60deg),
    oklch(${lightness} ${chroma} 90deg),
    oklch(${lightness} ${chroma} 120deg),
    oklch(${lightness} ${chroma} 150deg),
    oklch(${lightness} ${chroma} 180deg),
    oklch(${lightness} ${chroma} 210deg),
    oklch(${lightness} ${chroma} 240deg),
    oklch(${lightness} ${chroma} 270deg),
    oklch(${lightness} ${chroma} 300deg),
    oklch(${lightness} ${chroma} 330deg),
    oklch(${lightness} ${chroma} 360deg)
)`;
  };

  function setL(e) {
    let v = parseFloat(e.target.value);
    if (isNaN(v)) {
      v = 0;
    }
    setLightness(v);
    let textValue = `oklch(${v} ${chroma} ${hue}deg)`
   // setOklch(textValue);
    handleColorChange("oklch", textValue)
  }

  function setC(e) {
    let v = parseFloat(e.target.value);
    if (isNaN(v)) {
      v = 0;
    }
    setChroma(v);
    let textValue = `oklch(${lightness} ${v} ${hue}deg)`
   // setOklch(textValue);
    handleColorChange("oklch", textValue)
  }

  function setH(e) {
    let v = parseFloat(e.target.value);
    if (isNaN(v)) {
      v = 0;
    }
    setHue(v);
    let textValue = `oklch(${lightness} ${chroma} ${v}deg)`
   // setOklch(textValue);
    handleColorChange("oklch", textValue)
  }

  function hexToSixDigits(hex) {
    hex = hex.replace("#", "");

    // Convert 3-digit hex to 6-digit
    if (hex.length === 3) {
      return (
        "#" +
        hex
          .split("")
          .map((char) => char.repeat(2))
          .join("")
      );
    }

    return "#" + hex;
  }

  function parseOKLCH(oklchString) {
    // Remove 'oklch(' and ')', handle both space and comma separation
    const cleaned = oklchString
      .toLowerCase()
      .replace("oklch(", "")
      .replace(")", "");
    const values = cleaned.split(/[\s,]+/);

    // Parse each component
    let lightness =
      parseFloat(values[0]) * (values[0].includes("%") ? 0.01 : 1);
    let chroma = parseFloat(values[1]);
    let hue = parseFloat(values[2].replace("deg", ""));
    if (isNaN(lightness) || lightness === "" || lightness === undefined) {
      lightness = 0;
    }
    if (isNaN(hue) || hue === "" || hue === undefined) {
      hue = 0;
    }
    if (isNaN(chroma) || chroma === "" || chroma === undefined) {
      chroma = 0;
    }

    if (lightness < 0 || lightness > 1) {
      throw new Error("Lightness should be a percent.");
    }

    if (chroma < 0 || chroma > 1) {
      throw new Error("Chroma should be a percent.");
    }

    if (hue < 0 || hue > 360) {
      throw new Error("Hue should be a degress.");
    }

    return {
      lightness, // 0 to 1
      chroma, //0 to 1
      hue, // 0 to 360
    };
  }

  // Function to update color when a text field changes
  const handleColorChange = useMemo(
    () =>
    debounce((format, value) => {
      try {
        const color = new Color(value);
        let hexString = color.to("srgb").toString({ format: "hex" });
        // The above will convert 6 digit hex to 3 digit hex if possible. 
        // The following will revert that change.
        if (
          hexString.charAt(0) === "#" &&
          hexString.length === 4 &&
          value.length === 7
        ) {
          hexString = hexToSixDigits(hexString);
        }
        setHex(hexString);
        setRgb(color.to("srgb").toString({ format: "rgb" }));
        setHsl(color.to("hsl").toString({ format: "hsl" }));
        let okclh = color.to("oklch").toString({ format: "oklch" });
        setOklch(okclh)

        let values = parseOKLCH(okclh);
        setLightness(values.lightness);
        setChroma(values.chroma);
        setHue(values.hue);

        setHexError("");
        setRgbError("");
        setHslError("");
        setOklchError("");
      } catch (e) {
        console.log(e)
        const errorMessage = "Invalid color format";
        if (format === "hex") setHexError(errorMessage);
        if (format === "rgb") setRgbError(errorMessage);
        if (format === "hsl") setHslError(errorMessage);
        if (format === "oklch") setOklchError(errorMessage);
      }
    }, 500),
    [ setHex,
      setRgb,
      setHsl,
      setOklch,
      setLightness,
      setChroma,
      setHue,
      setHexError,
      setRgbError,
      setHslError,
      setOklchError]
  );


  // cancel debounce when view is unmounted.
  useEffect(() => {
    return () => {
      handleColorChange.cancel();
    }
  }, [handleColorChange]);

  return (
    <div>
      <Helmet>
              <title>OKLCH Converter</title>
              <meta
                name="description"
                content="Calculate and convert between OKLCH and formats like RBG, HEX, and HSL."
              />
            </Helmet>
    <div className="flex flex-col items-center p-4 space-y-4 bg-white rounded shadow-lg">
      <TitleText title={"OKLCH Color Picker"} subtitle={"Convert from and to OKLCH"} />   

      {/* Lightness Slider */}
      <div className="w-full">
        <label className="label">
          <span className="label-text">Lightness: {lightness.toFixed(2)}</span>
        </label>
        <input
          type="range"
          min="0"
          max="1"
          step="0.01"
          value={lightness}
          onChange={(e) => setL(e)}
          className="range [--range-shdw:transparent] w-full"
          style={{ background: getLightnessGradient() }}
        />
      </div>

      {/* Chroma Slider */}
      <div className="w-full">
        <label className="label">
          <span className="label-text">Chroma: {chroma.toFixed(2)}</span>
        </label>
        <input
          type="range"
          min="0"
          max="1"
          step="0.01"
          value={chroma}
          onChange={(e) => setC(e)}
          className="range [--range-shdw:transparent] w-full"
          style={{ background: getChromaGradient() }}
        />
      </div>

      {/* Hue Slider */}
      <div className="w-full">
        <label className="label">
          <span className="label-text">Hue: {hue.toFixed(0)}°</span>
        </label>
        <input
          type="range"
          min="0"
          max="360"
          step="1"
          value={hue}
          onChange={(e) => setH(e)}
          className="range [--range-shdw:transparent] w-full"
          style={{ background: getHueGradient() }}
        />
      </div>

      {/* Display the chosen color */}
      <div
        className="w-24 h-24 rounded-full mt-4"
        style={{ backgroundColor: currentColor }}
      />
      <p>Current Color: {currentColor}</p>

      {/* OKLCH input */}
      <div className="form-control">
        <label className="input-group">
          <span className="label-text">OKLCH</span>
          <input
            type="text"
            value={oklch}
            onChange={(e) => {
              setOklch(e.target.value);
              handleColorChange("oklch", e.target.value);
            }}
            className="input input-bordered"
          />
        </label>
        {oklchError && <p className="text-red-500 mt-2">{oklchError}</p>}
      </div>

      {/* HEX input */}
      <div className="form-control">
        <label className="label">
          <span className="label-text">HEX</span>
          <input
            type="text"
            value={hex}
            onChange={(e) => {
              let value = e.target.value;
              if (value.charAt(0) !== "#") {
                value = "#" + value;
              }
              setHex(value);
              handleColorChange("hex", value);
            }}
            className="input input-bordered"
          />
        </label>
        {hexError && <p className="text-red-500 mt-2">{hexError}</p>}
      </div>

      {/* RGB input */}
      <div className="form-control">
        <label className="label">
          <span className="label-text">RGB</span>
          <input
            type="text"
            value={rgb}
            onChange={(e) => {
              setRgb(e.target.value);
              handleColorChange("rgb", e.target.value);
            }}
            className="input input-bordered w-full"
          />
        </label>
        {rgbError && <p className="text-red-500 mt-2">{rgbError}</p>}
      </div>

      {/* HSL input */}
      <div className="form-control">
        <label className="label">
          <span className="label-text">HSL</span>
          <input
            type="text"
            value={hsl}
            onChange={(e) => {
              setHsl(e.target.value);
              handleColorChange("hsl", e.target.value);
            }}
            className="input input-bordered w-full"
          />
        </label>
        {hslError && <p className="text-red-500 mt-2">{hslError}</p>}
      </div>
    </div>
    <Description title={"OKLCH Converter and PickerHS"} mainText={mainText} />
    </div>
  );
}

export default OklchCalc;
