import React, { useState } from "react";
import { foods } from "./SmoothieFoods";
import { Helmet } from "react-helmet";
import TitleText from "../Helpers/TitleText";
import Description from '../Helpers/Description';

const mainText = `<p>
A smoothie is not always healthy. They can easily have too much sugar, too many calories, or too much fat. The best way to figure out how healthy a smoothie is, is to add the smoothie ingredients into a calculator.
</p>
<p>
This is an accurate smoothie calculator that tells you exactly how many calories, protein, fats, and carbs a smoothie recipe has. You can use this smoothie calculator to create the healthiest and best smoothies for your and your family.
</p>
<p>
This smoothie nutrition calculator also lets you easily convert the amounts of each food from tablespoons, teaspoons, and cups. That way you can measure the exact nutritional value of what you are adding to your smoothie.
</p>`;

// Conversion factors
const unitConversionToCups = {
  Cup: 1,
  Tablespoon: 1 / 16,
  Teaspoon: 1 / 48,
};

const SmoothieCalculator = () => {
  const [selectedIngredients, setSelectedIngredients] = useState([]);

  const addIngredient = () => {
    setSelectedIngredients([
      ...selectedIngredients,
      { food: null, servingSize: 1, unit: "Cup" },
    ]);
  };

  const removeIngredient = (index) => {
    setSelectedIngredients(selectedIngredients.filter((_, i) => i !== index));
  };

  const updateIngredient = (index, field, value) => {
    const updatedIngredients = [...selectedIngredients];
    updatedIngredients[index][field] = value;
    setSelectedIngredients(updatedIngredients);
  };

  const calculateIngredientNutrition = (ingredient) => {
    const food = foods.find(
      (item) => item.item_name === ingredient.food
    );

    if (!food) return null;

    // Convert the serving size to cups
    const servingSizeInCups =
      ingredient.servingSize * unitConversionToCups[ingredient.unit];

    // Calculate the multiplier based on the food's serving size in cups
    const multiplier =
      servingSizeInCups / (food.nf_serving_size_qty || 1); // Avoid division by zero

    return {
      calories: food.nf_calories * multiplier,
      fat: food.nf_total_fat * multiplier,
      carbs: food.nf_total_carbohydrate * multiplier,
      protein: food.nf_protein * multiplier,
    };
  };

  const calculateTotals = () => {
    return selectedIngredients.reduce(
      (totals, ingredient) => {
        const ingredientNutrition = calculateIngredientNutrition(ingredient);
        if (ingredientNutrition) {
          totals.calories += ingredientNutrition.calories;
          totals.fat += ingredientNutrition.fat;
          totals.carbs += ingredientNutrition.carbs;
          totals.protein += ingredientNutrition.protein;
        }
        return totals;
      },
      { calories: 0, fat: 0, carbs: 0, protein: 0 }
    );
  };

  const totals = calculateTotals();

  return (
    <div className="p-5">
      <Helmet>
              <title>Smoothie Calculator</title>
              <meta
                name="description"
                content="Calculate the true nutritional value of any smoothie. The most accurate health smoothie calculator on the internet."
              />
            </Helmet>
            <TitleText title={"Smoothie Nutrition Calculator"} subtitle={"Create the healthiest smoothie recipies."} />
      {selectedIngredients.map((ingredient, index) => {
        const ingredientNutrition = calculateIngredientNutrition(ingredient);

        return (
          <div key={index} className="flex flex-col gap-2 mb-4 border p-4 rounded shadow-md">
            <div className="flex items-center gap-4">
              <select
                className="select select-bordered w-full max-w-xs"
                value={ingredient.food || ""}
                onChange={(e) =>
                  updateIngredient(index, "food", e.target.value)
                }
              >
                <option value="" disabled>
                  Select Food
                </option>
                {foods.map((food) => (
                  <option key={food.item_name} value={food.item_name}>
                    {food.item_name}
                  </option>
                ))}
              </select>
              <input
                type="number"
                min="0.1"
                step="0.1"
                className="input input-bordered w-20"
                value={ingredient.servingSize}
                onChange={(e) =>
                  updateIngredient(index, "servingSize", parseFloat(e.target.value))
                }
              />
              <select
                className="select select-bordered w-32"
                value={ingredient.unit}
                onChange={(e) => updateIngredient(index, "unit", e.target.value)}
              >
                <option value="Cup">Cup</option>
                <option value="Tablespoon">Tablespoon</option>
                <option value="Teaspoon">Teaspoon</option>
              </select>
              <button
                className="btn btn-error"
                onClick={() => removeIngredient(index)}
              >
                Remove
              </button>
              {ingredientNutrition && (
              <div className="text-sm">
                <p>Calories: {ingredientNutrition.calories.toFixed(2)}</p>
                <p>Total Fat: {ingredientNutrition.fat.toFixed(2)}g</p>
                <p>Carbs: {ingredientNutrition.carbs.toFixed(2)}g</p>
                <p>Protein: {ingredientNutrition.protein.toFixed(2)}g</p>
              </div>
            )}
            </div>
          </div>
        );
      })}
      <button className="btn btn-primary mb-4" onClick={addIngredient}>
        Add Ingredient
      </button>
      <div className="p-4 border rounded shadow-md">
        <h2 className="text-xl font-bold mb-2">Smoothie Nutrition Totals</h2>
        <p>Calories: {totals.calories.toFixed(2)}</p>
        <p>Total Fat: {totals.fat.toFixed(2)}g</p>
        <p>Total Carbohydrates: {totals.carbs.toFixed(2)}g</p>
        <p>Protein: {totals.protein.toFixed(2)}g</p>
      </div>

      <Description title={"Smoothie Health Calcualtor"} mainText={mainText} />

    </div>
  );
};

export default SmoothieCalculator;
