import React, { useState } from "react";
import TitleText from "../Helpers/TitleText";
import Description from "../Helpers/Description";
import { Helmet } from "react-helmet";

// Constants for triathlon race distances
const DISTANCES = {
  sprint: {
    swim: 0.75, // kilometers
    bike: 20, // kilometers
    run: 5, // kilometers
  },
  olympic: {
    swim: 1.5,
    bike: 40,
    run: 10,
  },
  halfIronman: {
    swim: 1.9,
    bike: 90,
    run: 21.1, // half marathon
  },
  fullIronman: {
    swim: 3.86,
    bike: 180.2,
    run: 42.2, // full marathon
  },
};

const VALUE_NAMES = Object.freeze({
  swimPace: "swimPace",
  swimUnit: "swimUnit",
  bikePace: "bikePace",
  bikeUnit: "bikeUnit",
  runPace: "runPace",
  runUnit: "runUnit",
  transitionOne: "T1",
  transitionTwo: "T2",
  sprintTotal: "sprintTotal",
  olympicTotal: "olympicTotal",
  halfIronmanTotal: "halfIronmanTotal",
  fullIronmanTotal: "fullIronmanTotal",
});

const mainText = `triathlon performance calculator`;

const TriathlonCalculator = () => {
  const [swimPace, setSwimPace] = useState("2:00"); // Swim pace input value
  const [swimUnit, setSwimUnit] = useState("min/100m"); // Swim pace unit
  const [bikePace, setBikePace] = useState("24"); // Bike pace input value
  const [bikeUnit, setBikeUnit] = useState("km/h"); // Bike pace unit
  const [runPace, setRunPace] = useState("6:00"); // Run pace input value
  const [runUnit, setRunUnit] = useState("min/km"); // Run pace unit
  const [transitionOne, setTransitionOne] = useState("2:00"); // Transition 1 time
  const [transitionTwo, setTransitionTwo] = useState("1:00"); // Transition 2 time

  // Convert time string to seconds
  const stringToSeconds = (timeString) => {
    const timeNums = timeString.split(":");
    let mins = 0,
      secs = 0;

    if (timeNums.length === 2) {
      [mins, secs] = timeNums.map(Number);
    } else if (timeNums.length === 1) {
      secs = Number(timeNums[0]);
    } else {
      throw new Error("Please use MM:SS format");
    }

    return secs + mins * 60;
  };

  // Convert swim pace to seconds per 100 meters
  const convertSwimPace = (pace, unit) => {
    const paceSeconds = stringToSeconds(pace);
    switch (unit) {
      case "min/100m":
        return paceSeconds;
      case "min/100y":
        return paceSeconds * 0.9144; // 1 yard = 0.9144 meters
      default:
        throw new Error("Unsupported swim unit");
    }
  };

  // Convert bike pace to speed in km/h
  const convertBikePace = (pace, unit) => {
    const paceFloat = parseFloat(pace);
    switch (unit) {
      case "km/h":
        return paceFloat;
      case "mph":
        return paceFloat * 1.60934; // 1 mile = 1.60934 kilometers
      default:
        throw new Error("Unsupported bike unit");
    }
  };

  // Convert run pace to seconds per kilometer
  const convertRunPace = (pace, unit) => {
    const paceSeconds = stringToSeconds(pace);
    switch (unit) {
      case "min/km":
        return paceSeconds;
      case "min/mile":
        return paceSeconds * 1.60934; // 1 mile = 1.60934 kilometers
      default:
        throw new Error("Unsupported run unit");
    }
  };

  // Convert seconds to "2:23:45" format
  const secondsToString = (totalSeconds) => {
    let hours = Math.floor(totalSeconds / 3600);
    let minutes = Math.floor((totalSeconds % 3600) / 60);
    let seconds = Math.round(totalSeconds % 60);

    // Handle seconds rounded to 60
    if (seconds === 60) {
      seconds = 0;
      minutes += 1;
      if (minutes === 60) {
        minutes = 0;
        hours += 1;
      }
    }
    const pad = (num) => String(num).padStart(2, "0");
    return hours > 0
      ? `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`
      : `${pad(minutes)}:${pad(seconds)}`;
  };

  const calculateTotalTime = () => {
    try {
      if (
        swimPace === undefined ||
        swimPace === "" ||
        !swimPace.includes(":")
      ) {
        throw new Error("missing swim pace, use MM:SS format");
      }
      if (bikePace === undefined || bikePace === "") {
        throw new Error("missing bicycle pace");
      }
      if (runPace === undefined || runPace === "" || !runPace.includes(":")) {
        throw new Error("missing running pace, use MM:SS format");
      }
      // Swim calculations
      const swimPaceSeconds = convertSwimPace(swimPace, swimUnit);
      const sprintSwimTime = swimPaceSeconds * DISTANCES.sprint.swim;
      const olympicSwimTime = swimPaceSeconds * DISTANCES.olympic.swim;
      const halfIronmanSwimTime = swimPaceSeconds * DISTANCES.halfIronman.swim;
      const fullIronmanSwimTime = swimPaceSeconds * DISTANCES.fullIronman.swim;

      // Bike calculations
      const bikeSpeedKmH = convertBikePace(bikePace, bikeUnit);
      const sprintBikeTime = (DISTANCES.sprint.bike / bikeSpeedKmH) * 3600;
      const olympicBikeTime = (DISTANCES.olympic.bike / bikeSpeedKmH) * 3600;
      const halfIronmanBikeTime =
        (DISTANCES.halfIronman.bike / bikeSpeedKmH) * 3600;
      const fullIronmanBikeTime =
        (DISTANCES.fullIronman.bike / bikeSpeedKmH) * 3600;

      // Run calculations
      const runPaceSeconds = convertRunPace(runPace, runUnit);
      const sprintRunTime = runPaceSeconds * DISTANCES.sprint.run;
      const olympicRunTime = runPaceSeconds * DISTANCES.olympic.run;
      const halfIronmanRunTime = runPaceSeconds * DISTANCES.halfIronman.run;
      const fullIronmanRunTime = runPaceSeconds * DISTANCES.fullIronman.run;

      // Transition times
      const transOneSeconds = stringToSeconds(transitionOne || "00:00");
      const transTwoSeconds = stringToSeconds(transitionTwo || "00:00");

      // Total times for each race
      const sprintTotalTime =
        sprintSwimTime +
        sprintBikeTime +
        sprintRunTime +
        transOneSeconds +
        transTwoSeconds;
      const olympicTotalTime =
        olympicSwimTime +
        olympicBikeTime +
        olympicRunTime +
        transOneSeconds +
        transTwoSeconds;
      const halfIronmanTotalTime =
        halfIronmanSwimTime +
        halfIronmanBikeTime +
        halfIronmanRunTime +
        transOneSeconds +
        transTwoSeconds;
      const fullIronmanTotalTime =
        fullIronmanSwimTime +
        fullIronmanBikeTime +
        fullIronmanRunTime +
        transOneSeconds +
        transTwoSeconds;

      if (isNaN(sprintTotalTime)) {
        throw new Error("Please make sure each field has a valid input.");
      }
      return [
        secondsToString(sprintTotalTime),
        secondsToString(olympicTotalTime),
        secondsToString(halfIronmanTotalTime),
        secondsToString(fullIronmanTotalTime),
        null,
      ];
    } catch (error) {
      return ["", "", "", "", error.message];
    }
  };

  const handleInputChange = (e, field) => {
    const value = e.target.value;

    switch (field) {
      case VALUE_NAMES.swimPace:
        setSwimPace(value);
        break;
      case VALUE_NAMES.swimUnit:
        setSwimUnit(value);
        break;
      case VALUE_NAMES.bikePace:
        setBikePace(value);
        break;
      case VALUE_NAMES.bikeUnit:
        setBikeUnit(value);
        break;
      case VALUE_NAMES.runPace:
        setRunPace(value);
        break;
      case VALUE_NAMES.runUnit:
        setRunUnit(value);
        break;
      case VALUE_NAMES.transitionOne:
        setTransitionOne(value);
        break;
      case VALUE_NAMES.transitionTwo:
        setTransitionTwo(value);
        break;
      default:
        break;
    }
  };

  const [
    sprintTotal,
    olympicTotal,
    halfIronmanTotal,
    fullIronmanTotal,
    errorText,
  ] = calculateTotalTime();

  return (
    <div>
      <Helmet>
        <title>Triathlon Pace Calculator</title>
        <meta
          name="description"
          content="Easily figure out your triathlon performance for different race distances based on your running, swimming, and cycling pace."
        />
      </Helmet>
      <div className="p-6 max-w-lg mx-auto space-y-4">
        <TitleText
          title="Triathlon Performance Calculator"
          subtitle="Estimate your race times across different triathlon distances"
        />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="form-control">
            <label className="label">
              <span className="label-text">Swim Pace</span>
            </label>
            <input
              type="text"
              className="input input-bordered w-full"
              value={swimPace}
              onChange={(e) => handleInputChange(e, VALUE_NAMES.swimPace)}
              placeholder="MM:SS"
            />
            <select
              className="select select-bordered mt-2"
              value={swimUnit}
              onChange={(e) => handleInputChange(e, VALUE_NAMES.swimUnit)}
            >
              <option value="min/100m">min/100m</option>
              <option value="min/100y">min/100y</option>
            </select>
          </div>

          <div className="form-control">
            <label className="label">
              <span className="label-text">Bike Pace</span>
            </label>
            <input
              type="number"
              className="input input-bordered w-full"
              value={bikePace}
              onChange={(e) => handleInputChange(e, VALUE_NAMES.bikePace)}
              placeholder="0.0"
              step="0.1"
              min="0"
            />
            <select
              className="select select-bordered mt-2"
              value={bikeUnit}
              onChange={(e) => handleInputChange(e, VALUE_NAMES.bikeUnit)}
            >
              <option value="km/h">km/h</option>
              <option value="mph">mph</option>
            </select>
          </div>

          <div className="form-control">
            <label className="label">
              <span className="label-text">Run Pace</span>
            </label>
            <input
              type="text"
              className="input input-bordered w-full"
              value={runPace}
              onChange={(e) => handleInputChange(e, VALUE_NAMES.runPace)}
              placeholder="MM:SS"
            />
            <select
              className="select select-bordered mt-2"
              value={runUnit}
              onChange={(e) => handleInputChange(e, VALUE_NAMES.runUnit)}
            >
              <option value="min/km">min/km</option>
              <option value="min/mile">min/mile</option>
            </select>
          </div>

          <div className="form-control">
            <label className="label">
              <span className="label-text">Transition 1 Time (T1)</span>
            </label>
            <input
              type="text"
              className="input input-bordered w-full"
              value={transitionOne}
              onChange={(e) => handleInputChange(e, VALUE_NAMES.transitionOne)}
              placeholder="MM:SS"
            />
          </div>

          <div className="form-control">
            <label className="label">
              <span className="label-text">Transition 2 Time (T2)</span>
            </label>
            <input
              type="text"
              className="input input-bordered w-full"
              value={transitionTwo}
              onChange={(e) => handleInputChange(e, VALUE_NAMES.transitionTwo)}
              placeholder="MM:SS"
            />
          </div>
        </div>

        <h3 className="text-lg font-semibold text-gray-700 mt-4">
          Estimated Race Times
        </h3>

        <p className="text-red-500 mt-2">{errorText}</p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="form-control">
            <label className="label">
              <span className="label-text">Sprint Total Time</span>
            </label>
            <input
              type="text"
              className="input input-bordered w-full"
              value={sprintTotal}
              readOnly
              placeholder="HH:MM:SS"
            />
          </div>

          <div className="form-control">
            <label className="label">
              <span className="label-text">Olympic Total Time</span>
            </label>
            <input
              type="text"
              className="input input-bordered w-full"
              value={olympicTotal}
              readOnly
              placeholder="HH:MM:SS"
            />
          </div>

          <div className="form-control">
            <label className="label">
              <span className="label-text">Half Ironman Total Time</span>
            </label>
            <input
              type="text"
              className="input input-bordered w-full"
              value={halfIronmanTotal}
              readOnly
              placeholder="HH:MM:SS"
            />
          </div>

          <div className="form-control">
            <label className="label">
              <span className="label-text">Full Ironman Total Time</span>
            </label>
            <input
              type="text"
              className="input input-bordered w-full"
              value={fullIronmanTotal}
              readOnly
              placeholder="HH:MM:SS"
            />
          </div>
        </div>
      </div>
      <Description
        title="Triathlon Performance Calculator"
        mainText={mainText}
      />
    </div>
  );
};

export default TriathlonCalculator;
