import React, { useState } from "react";
import TitleText from "../Helpers/TitleText"
import Description from "../Helpers/Description";
import { Helmet } from "react-helmet";

const ApyCalculator = () => {
  // State variables for inputs and outputs
  const [initialAmount, setInitialAmount] = useState(1000);
  const [apy, setApy] = useState(5.12675); // Annual Percentage Yield in %
  const [apr, setApr] = useState(5); // Annual Percentage Rate in %
  const [compoundFrequency, setCompoundFrequency] = useState(365); // Daily compounding
  const [monthlyDeposit, setMonthlyDeposit] = useState(0);
  const [durationMonths, setDurationMonths] = useState(12); // Duration in months
  const [finalAmount, setFinalAmount] = useState(0);
  const [dailyIncome, setDailyIncome] = useState(0);
  const [weeklyIncome, setWeeklyIncome] = useState(0);
  const [monthlyIncome, setMonthlyIncome] = useState(0);
  const [yearlyIncome, setYearlyIncome] = useState(0);

  const mainText = `<p>Interest represents the cost of borrowing money or the compensation
            for lending money, typically expressed as a percentage of the
            principal amount. Annual Percentage Yield (APY) reflects the total
            amount of interest earned on a savings account in one year,
            accounting for compounding effects where interest is calculated not
            just on the initial principal but also on previously accumulated
            interest. Banks calculate interest by multiplying the account's
            principal balance by the annual interest rate and dividing by the
            number of compounding periods (daily, monthly, or annually), which
            means a savings account with a 2% annual interest rate and daily
            compounding will generate more total interest than an account with
            the same rate compounded annually. For consumers, this means money
            deposited in a savings account earns additional funds over time
            through this interest mechanism, with higher APY rates offering more
            attractive returns on saved funds.</p>`;

  // Convert APR to APY
  function aprToApy(apr, compoundingPeriods) {
    const num =
      (Math.pow(1 + apr / 100 / compoundingPeriods, compoundingPeriods) - 1) *
      100;
    return parseFloat(num.toFixed(5));
  }

  // Convert APY to APR
  function apyToApr(apy, compoundingPeriods) {
    const num =
      100 *
      compoundingPeriods *
      (Math.pow(1 + apy / 100, 1 / compoundingPeriods) - 1);
    return parseFloat(num.toFixed(5));
  }

  const updateAPY = (newAPY) => {
    setApy(newAPY);
    const calculatedApr = apyToApr(newAPY, compoundFrequency);
    setApr(calculatedApr);
    calculateFinalAmount();
  };

  const updateAPR = (newAPR) => {
    setApr(newAPR);
    const calculatedApy = aprToApy(newAPR, compoundFrequency);
    setApy(calculatedApy);
    calculateFinalAmount();
  };

  const updateCompoundFrequency = (newFreq) => {
    setCompoundFrequency(newFreq);
    const calculatedApy = aprToApy(apr, newFreq);
    setApy(calculatedApy);
    calculateFinalAmount();
  };

  const updateInitialAmount = (amount) => {
    setInitialAmount(amount);
    calculateFinalAmount();
  };

  const updateMonthlyDeposit = (newAPR) => {
    setMonthlyDeposit(newAPR);
    calculateFinalAmount();
  };

  const updateDurationMonths = (newAPR) => {
    setDurationMonths(newAPR);
    calculateFinalAmount();
  };

  // Function to calculate compound interest
  const calculateFinalAmount = () => {
    const n = apr / 100;
    const oDivBy = 12 / compoundFrequency;

    const compoundInterest =
      initialAmount *
      Math.pow(1 + n / compoundFrequency, durationMonths / oDivBy);

    // Calculate growth of monthly deposits
    const depositGrowth =
      (monthlyDeposit / (n / 12)) *
      (Math.pow(1 + n / compoundFrequency, durationMonths / oDivBy) - 1);

    // Total final amount
    const total = compoundInterest + depositGrowth;

    const interestEarned =
      total - initialAmount - monthlyDeposit * durationMonths;
    const dailyInterest = interestEarned / (durationMonths * 30);
    const weeklyInterest = dailyInterest * 7;
    const monthlyInterest = interestEarned / durationMonths;
    const yearlyInterest = monthlyInterest * 12;

    setFinalAmount(total.toFixed(2));
    setDailyIncome(dailyInterest.toFixed(2));
    setWeeklyIncome(weeklyInterest.toFixed(2));
    setMonthlyIncome(monthlyInterest.toFixed(2));
    setYearlyIncome(yearlyInterest.toFixed(2));
  };

  return (
    <div>
      <Helmet>
        <title>APY Interest Calculator</title>
        <meta
          name="description"
          content="An easy to use APY interest calculator. See how much interest you would earn daily, monthly, or yearly."
        />
      </Helmet>
    <div className="p-6 bg-base-200 rounded-lg shadow-lg">
    <TitleText title={"APY Interest Calculator"} subtitle={"Calculate annualized, monthly, and daily returns."} />

      {/* Input Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="form-control">
          <label className="label">Starting Amount ($)</label>
          <input
            type="number"
            className="input input-bordered"
            value={initialAmount}
            onChange={(e) => updateInitialAmount(Number(e.target.value))}
          />
        </div>

        <div className="form-control">
          <label className="label">APY (%)</label>
          <input
            type="number"
            className="input input-bordered"
            value={apy}
            onChange={(e) => updateAPY(Number(e.target.value))}
          />
        </div>

        <div className="form-control">
          <label className="label">APR (%)</label>
          <input
            type="number"
            className="input input-bordered"
            value={apr}
            onChange={(e) => updateAPR(Number(e.target.value))}
          />
        </div>

        <div className="form-control">
          <label className="label">Compound Frequency</label>
          <select
            className="select select-bordered"
            value={compoundFrequency}
            onChange={(e) => updateCompoundFrequency(Number(e.target.value))}
          >
            <option value={365}>Daily</option>
            <option value={52}>Weekly</option>
            <option value={12}>Monthly</option>
            <option value={4}>Quarterly</option>
            <option value={1}>Annually</option>
          </select>
        </div>

        <div className="form-control">
          <label className="label">Monthly Deposit ($)</label>
          <input
            type="number"
            className="input input-bordered"
            value={monthlyDeposit}
            onChange={(e) => updateMonthlyDeposit(Number(e.target.value))}
          />
        </div>

        <div className="form-control">
          <label className="label">Duration (months)</label>
          <input
            type="number"
            className="input input-bordered"
            value={durationMonths}
            onChange={(e) => updateDurationMonths(Number(e.target.value))}
          />
        </div>
      </div>

      {/* Results Section */}
      <div className="mt-6">
        <h3 className="text-xl font-semibold">Results</h3>
        <p>Final Amount: ${finalAmount}</p>
        <p>Average Daily Interest: ${dailyIncome}</p>
        <p>Average Weekly Interest: ${weeklyIncome}</p>
        <p>Average Monthly Interest: ${monthlyIncome}</p>
        <p>Average Annual Interest: ${yearlyIncome}</p>
      </div>
    </div>
    <Description title={"Interest Rate Calculations"} mainText={mainText}/>
    </div>
  );
};

export default ApyCalculator;
