export const foods = [{
    item_name: "Agave Nectar",
    brand_name: "USDA",
    nf_calories: 21,
    nf_total_fat: 0,
    nf_total_carbohydrate: 5,
    item_link: "https://amzn.to/2txAdE7",
    nf_protein: 0,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Tsp"
}, {
    item_name: "Almond Butter",
    item_link: "https://amzn.to/2JNCXr0",
    brand_name: "USDA",
    nf_calories: 95,
    nf_total_fat: 8.5,
    nf_total_carbohydrate: 3,
    nf_protein: 3.5,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Tbsp"
}, {
    item_name: "Almond Milk",
    nf_calories: 60,
    nf_total_fat: 2,
    nf_total_carbohydrate: 8,
    nf_protein: 1,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Almond Oil",
    item_link: "https://amzn.to/2LaIGET",
    nf_calories: 119,
    nf_total_fat: 13.5,
    nf_total_carbohydrate: 0,
    nf_protein: 0,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Tbsp"
}, {
    item_name: "Almonds",
    brand_name: "Trader Joes",
    nf_calories: 720,
    nf_total_fat: 60,
    nf_total_carbohydrate: 20,
    nf_protein: 28,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Aloe Vera Juice",
    nf_calories: 131,
    nf_total_fat: .3,
    nf_total_carbohydrate: 32,
    nf_protein: 1,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Apple",
    brand_name: "USDA",
    nf_calories: 95,
    nf_total_fat: 0,
    nf_total_carbohydrate: 25,
    nf_protein: 0,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Apple"
}, {
    item_name: "Apple Cider Vinegar",
    item_link: "https://amzn.to/2tw9Tue",
    brand_name: "USDA",
    nf_calories: 3,
    nf_total_fat: 0,
    nf_total_carbohydrate: .1,
    nf_protein: 0,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Tbsp"
}, {
    item_name: "Arugula",
    brand_name: "USDA",
    nf_calories: 6,
    nf_total_fat: 0,
    nf_total_carbohydrate: 1,
    nf_protein: .6,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Avocado",
    brand_name: "USDA",
    nf_calories: 321,
    nf_total_fat: 29,
    nf_total_carbohydrate: 17,
    nf_protein: 4,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Avocado"
}, {
    item_name: "Avocado Oil",
    item_link: "https://amzn.to/2K2H2c4",
    brand_name: "USDA",
    nf_calories: 124,
    nf_total_fat: 14,
    nf_total_carbohydrate: 0,
    nf_protein: 0,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Tbsp"
}, {
    item_name: "Bananas",
    nf_calories: 105,
    nf_total_fat: .4,
    nf_total_carbohydrate: 27,
    nf_protein: 1.3,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Banana"
}, {
    item_name: "Beets",
    nf_calories: 59,
    nf_total_fat: .2,
    nf_total_carbohydrate: 13,
    nf_protein: 2.2,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Blueberries",
    nf_calories: 62,
    nf_total_fat: .7,
    nf_total_carbohydrate: 14,
    nf_protein: 2,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Blackberries",
    nf_calories: 85,
    nf_total_fat: .5,
    nf_total_carbohydrate: 21,
    nf_protein: 1.1,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Broccoli",
    nf_calories: 31,
    nf_total_fat: .3,
    nf_total_carbohydrate: 6,
    nf_protein: 2.6,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Calona Oil",
    nf_calories: 120,
    nf_total_fat: 14,
    nf_total_carbohydrate: 0,
    nf_protein: 0,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Tbsp"
}, {
    item_name: "Cantaloupe",
    nf_calories: 54,
    nf_total_fat: 0,
    nf_total_carbohydrate: 13,
    nf_protein: 1.3,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Carrot",
    nf_calories: 25,
    nf_total_fat: 0,
    nf_total_carbohydrate: 6,
    nf_protein: .6,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Carrot"
}, {
    item_name: "Casein Protein",
    item_link: "https://amzn.to/2OFLYRA",
    brand_name: "usa",
    nf_calories: 120,
    nf_total_fat: 1,
    nf_total_carbohydrate: 3,
    nf_protein: 24,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Scoop"
}, {
    item_name: "Cashew Butter",
    brand_name: "MaraNatha",
    nf_calories: 95,
    nf_total_fat: 7.5,
    nf_total_carbohydrate: 5,
    nf_protein: 2.5,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Tbsp"
}, {
    item_name: "Cashew Milk, Original",
    brand_name: "Silk",
    nf_calories: 60,
    nf_total_fat: 2.5,
    nf_total_carbohydrate: 9,
    nf_protein: .5,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Cashew Milk, Unsweetened",
    brand_name: "Silk",
    nf_calories: 25,
    nf_total_fat: 2,
    nf_total_carbohydrate: 1,
    nf_protein: .5,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Celery",
    brand_name: "Silk",
    nf_calories: 6,
    nf_total_fat: .1,
    nf_total_carbohydrate: 1,
    nf_protein: .3,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Stalk"
}, {
    item_name: "Cherries",
    brand_name: "Silk",
    nf_calories: 77,
    nf_total_fat: .5,
    nf_total_carbohydrate: 19,
    nf_protein: 1.6,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Chia Seeds",
    brand_name: "bobsredmill",
    nf_calories: 60,
    nf_total_fat: 4.5,
    nf_total_carbohydrate: 5,
    nf_protein: 3,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Tbsp"
}, {
    item_name: "Chocolate, Dark",
    brand_name: "usda",
    nf_calories: 155,
    nf_total_fat: 9,
    nf_total_carbohydrate: 17,
    nf_protein: 1.4,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Ounce"
}, {
    item_name: "Chocolate Milk",
    brand_name: "usda",
    nf_calories: 209,
    nf_total_fat: 8,
    nf_total_carbohydrate: 26,
    nf_protein: 8,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Cilantro",
    brand_name: "usda",
    nf_calories: 4,
    nf_total_fat: 0,
    nf_total_carbohydrate: .5,
    nf_protein: .5,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Cinnamon",
    brand_name: "Silk",
    nf_calories: 19,
    nf_total_fat: .1,
    nf_total_carbohydrate: 6,
    nf_protein: .3,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Tbsp"
}, {
    item_name: "Cocoa Powder - Cup",
    nf_calories: 196,
    nf_total_fat: 12,
    nf_total_carbohydrate: 50,
    nf_protein: 17,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Coconut Meat",
    brand_name: "USDA",
    nf_calories: 283,
    nf_total_fat: 27,
    nf_total_carbohydrate: 12,
    nf_protein: 3,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Coconut Cream - Tbsp",
    brand_name: "USDA",
    nf_calories: 49,
    nf_total_fat: 5,
    nf_total_carbohydrate: 1,
    nf_protein: .5,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Tbsp"
}, {
    item_name: "Coconut Milk",
    brand_name: "Silk",
    nf_calories: 80,
    nf_total_fat: 5,
    nf_total_carbohydrate: 7,
    nf_protein: .5,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Coconut Oil",
    brand_name: "USDA",
    nf_calories: 117,
    nf_total_fat: 13.6,
    nf_total_carbohydrate: 0,
    nf_protein: 0,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Tbsp"
}, {
    item_name: "Coconut Water",
    brand_name: "USDA",
    nf_calories: 46,
    nf_total_fat: 0,
    nf_total_carbohydrate: 9,
    nf_protein: 2,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Coffee",
    brand_name: "USDA",
    nf_calories: 1,
    nf_total_fat: 0,
    nf_total_carbohydrate: 0,
    nf_protein: 0,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Collard Greens",
    brand_name: "USDA",
    nf_calories: 11,
    nf_total_fat: 0,
    nf_total_carbohydrate: 2,
    nf_protein: 1,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Cottage Cheese",
    brand_name: "USDA",
    nf_calories: 222,
    nf_total_fat: 10,
    nf_total_carbohydrate: 8,
    nf_protein: 25,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Creatine",
    brand_name: "USDA",
    nf_calories: 0,
    nf_total_fat: 0,
    nf_total_carbohydrate: 0,
    nf_protein: 0,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Tsp"
}, {
    item_name: "Cucumber",
    brand_name: "USDA",
    nf_calories: 47,
    nf_total_fat: .3,
    nf_total_carbohydrate: 11,
    nf_protein: 2,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cucumber"
}, {
    item_name: "Dates",
    brand_name: "USDA",
    nf_calories: 414,
    nf_total_fat: .6,
    nf_total_carbohydrate: 110,
    nf_protein: 3.6,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Dragon Fruit",
    brand_name: "USDA",
    nf_calories: 61,
    nf_total_fat: 0.5,
    nf_total_carbohydrate: 15,
    nf_protein: 1,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Dragon Fruit"
}, {
    item_name: "Durian",
    brand_name: "usda",
    nf_calories: 357,
    nf_total_fat: 13,
    nf_total_carbohydrate: 66,
    nf_protein: 3.6,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Egg (raw)",
    brand_name: "usda",
    nf_calories: 71,
    nf_total_fat: 5,
    nf_total_carbohydrate: 0,
    nf_protein: 6.3,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Egg"
}, {
    item_name: "Espresso",
    brand_name: "USDA",
    nf_calories: 1,
    nf_total_fat: 0,
    nf_total_carbohydrate: 0,
    nf_protein: 0,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Shot (1 oz)"
}, {
    item_name: "Flaxseed Meal",
    brand_name: "USDA",
    nf_calories: 30,
    nf_total_fat: 2.2,
    nf_total_carbohydrate: 2,
    nf_protein: 1.5,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Tbsp"
}, {
    item_name: "Garlic",
    brand_name: "USDA",
    nf_calories: 5,
    nf_total_fat: 0,
    nf_total_carbohydrate: 1,
    nf_protein: .2,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Clove"
}, {
    item_name: "Frozen Yogurt (nonfat)",
    nf_calories: 200,
    nf_total_fat: 0,
    nf_total_carbohydrate: 40,
    nf_protein: 8,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Ginger",
    brand_name: "USDA",
    nf_calories: 18,
    nf_total_fat: .2,
    nf_total_carbohydrate: 4,
    nf_protein: .4,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Tbsp"
}, {
    item_name: "Goat Milk",
    brand_name: "summerhill",
    nf_calories: 170,
    nf_total_fat: 10,
    nf_total_carbohydrate: 11,
    nf_protein: 9,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Goji Berries",
    brand_name: "USDA",
    nf_calories: 50,
    nf_total_fat: 0,
    nf_total_carbohydrate: 10.5,
    nf_protein: 2,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Tbsp"
}, {
    item_name: "Granola",
    brand_name: "USDA",
    nf_calories: 600,
    nf_total_fat: 24,
    nf_total_carbohydrate: 84,
    nf_protein: 16,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Grape",
    brand_name: "USDA",
    nf_calories: 62,
    nf_total_fat: .3,
    nf_total_carbohydrate: 16,
    nf_protein: .6,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Grapefruit",
    brand_name: "USDA",
    nf_calories: 97,
    nf_total_fat: .3,
    nf_total_carbohydrate: 25,
    nf_protein: 1.8,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Greek Yogurt",
    brand_name: "GreekPastures",
    nf_calories: 130,
    nf_total_fat: 0,
    nf_total_carbohydrate: 10,
    nf_protein: 23,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Green Beans",
    brand_name: "usda",
    nf_calories: 31,
    nf_total_fat: 0,
    nf_total_carbohydrate: 7,
    nf_protein: 1.8,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Green Tea",
    brand_name: "usda",
    nf_calories: 2,
    nf_total_fat: 0,
    nf_total_carbohydrate: .5,
    nf_protein: 0,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Guar Gum",
    brand_name: "bobs red mill",
    nf_calories: 30,
    nf_total_fat: 0,
    nf_total_carbohydrate: 9,
    nf_protein: 0,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Tbsp"
}, {
    item_name: "Half and Half",
    brand_name: "USDA",
    nf_calories: 315,
    nf_total_fat: 28,
    nf_total_carbohydrate: 10,
    nf_protein: 7,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Hazelnut Milk",
    brand_name: "Pacific",
    nf_calories: 110,
    nf_total_fat: 3.5,
    nf_total_carbohydrate: 19,
    nf_protein: 2,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Heavy Cream",
    brand_name: "USDA",
    nf_calories: 820,
    nf_total_fat: 88,
    nf_total_carbohydrate: 6,
    nf_protein: 5,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Hemp Hearts",
    brand_name: "manitoba",
    nf_calories: 56,
    nf_total_fat: 4.3,
    nf_total_carbohydrate: 1,
    nf_protein: 3.3,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Tbsp"
}, {
    item_name: "Hemp Milk",
    brand_name: "tempt",
    nf_calories: 100,
    nf_total_fat: 7,
    nf_total_carbohydrate: 8,
    nf_protein: 2,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Hemp Oil",
    brand_name: "tempt",
    nf_calories: 130,
    nf_total_fat: 14,
    nf_total_carbohydrate: 0,
    nf_protein: 0,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Tbsp"
}, {
    item_name: "Hemp Seed Butter",
    item_link: "https://amzn.to/2NJkkDZ",
    brand_name: "USDA",
    nf_calories: 72.5,
    nf_total_fat: 2.5,
    nf_total_carbohydrate: 3.5,
    nf_protein: 5.5,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Tbsp"
}, {
    item_name: "Hemp Seeds",
    brand_name: "USDA",
    nf_calories: 57,
    nf_total_fat: 4.7,
    nf_total_carbohydrate: .5,
    nf_protein: 3.7,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Tbsp"
}, {
    item_name: "Honey",
    brand_name: "USDA",
    nf_calories: 64,
    nf_total_fat: 0,
    nf_total_carbohydrate: 17,
    nf_protein: 0,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Tbsp"
}, {
    item_name: "Ice Cream",
    brand_name: "hagen daaz",
    nf_calories: 500,
    nf_total_fat: 34,
    nf_total_carbohydrate: 40,
    nf_protein: 8,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Iceberg Lettuce",
    brand_name: "hagen daaz",
    nf_calories: 8,
    nf_total_fat: 0,
    nf_total_carbohydrate: 1.7,
    nf_protein: .5,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Kale",
    brand_name: "usa",
    nf_calories: 33,
    nf_total_fat: 0,
    nf_total_carbohydrate: 6,
    nf_protein: 3,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Kefir - low fat",
    brand_name: "lifeway",
    nf_calories: 110,
    nf_total_fat: 2,
    nf_total_carbohydrate: 12,
    nf_protein: 11,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Kefir - full fat",
    brand_name: "lifeway",
    nf_calories: 160,
    nf_total_fat: 8,
    nf_total_carbohydrate: 12,
    nf_protein: 10,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Kiwifruit",
    brand_name: "usda",
    nf_calories: 42,
    nf_total_fat: 0,
    nf_total_carbohydrate: 10,
    nf_protein: 1,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Kiwi"
}, {
    item_name: "Kombucha",
    brand_name: "USDA",
    nf_calories: 0,
    nf_total_fat: 0,
    nf_total_carbohydrate: 0,
    nf_protein: 1,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Tbsp"
}, {
    item_name: "Lemon Juice",
    brand_name: "usa",
    nf_calories: 3,
    nf_total_fat: 0,
    nf_total_carbohydrate: 1,
    nf_protein: 0,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Tbsp"
}, {
    item_name: "Lentils",
    brand_name: "usa",
    nf_calories: 230,
    nf_total_fat: 0.8,
    nf_total_carbohydrate: 40,
    nf_protein: 18,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Lettuce",
    brand_name: "usa",
    nf_calories: 5,
    nf_total_fat: 0,
    nf_total_carbohydrate: 1,
    nf_protein: .5,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Lime Juice",
    brand_name: "usa",
    nf_calories: 60,
    nf_total_fat: 0,
    nf_total_carbohydrate: 20,
    nf_protein: 1,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Maca Powder",
    item_link: "https://amzn.to/2ROPkDF",
    nf_calories: 91,
    nf_total_fat: 1,
    nf_total_carbohydrate: 20,
    nf_protein: 4,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Tbsp"
}, {
    item_name: "Macadamia Butter",
    item_link: "https://amzn.to/2zyn1Fq",
    brand_name: "USDA",
    nf_calories: 97.5,
    nf_total_fat: 10,
    nf_total_carbohydrate: 2,
    nf_protein: 2,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Tbsp"
}, {
    item_name: "Mango",
    brand_name: "USDA",
    nf_calories: 99,
    nf_total_fat: .6,
    nf_total_carbohydrate: 25,
    nf_protein: 1.4,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Maple Syrup",
    brand_name: "USDA",
    nf_calories: 52,
    nf_total_fat: 0,
    nf_total_carbohydrate: 13,
    nf_protein: 0,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Tbsp"
}, {
    item_name: "Milk - Nonfat",
    brand_name: "USDA",
    nf_calories: 83,
    nf_total_fat: 0,
    nf_total_carbohydrate: 12,
    nf_protein: 8,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Milk - 2%",
    brand_name: "USDA",
    nf_calories: 120,
    nf_total_fat: 5,
    nf_total_carbohydrate: 12,
    nf_protein: 8,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Milk - Whole",
    brand_name: "USDA",
    nf_calories: 149,
    nf_total_fat: 8,
    nf_total_carbohydrate: 12,
    nf_protein: 8,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Nectarine",
    brand_name: "USDA",
    nf_calories: 63,
    nf_total_fat: .5,
    nf_total_carbohydrate: 15,
    nf_protein: 1.5,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Nectatine"
}, {
    item_name: "Nutmeg",
    brand_name: "USDA",
    nf_calories: 37,
    nf_total_fat: 2.5,
    nf_total_carbohydrate: 3.4,
    nf_protein: .4,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Tbsp"
}, {
    item_name: "Oats",
    brand_name: "USDA",
    nf_calories: 300,
    nf_total_fat: 3,
    nf_total_carbohydrate: 27,
    nf_protein: 5,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Oat Milk",
    brand_name: "USDA",
    nf_calories: 130,
    nf_total_fat: 2.5,
    nf_total_carbohydrate: 24,
    nf_protein: 4,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Okra",
    brand_name: "USDA",
    nf_calories: 33,
    nf_total_fat: .2,
    nf_total_carbohydrate: 7,
    nf_protein: 2,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Olive Oil",
    brand_name: "USDA",
    nf_calories: 119.3,
    nf_total_fat: 13.5,
    nf_total_carbohydrate: 0,
    nf_protein: 0,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Tbsp"
}, {
    item_name: "Orange",
    brand_name: "na",
    nf_calories: 62,
    nf_total_fat: .2,
    nf_total_carbohydrate: 15,
    nf_protein: 1.2,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Orange"
}, {
    item_name: "Orange Juice",
    brand_name: "Simply Orange",
    nf_calories: 110,
    nf_total_fat: 0,
    nf_total_carbohydrate: 26,
    nf_protein: 2,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Palm Oil",
    brand_name: "USDA",
    nf_calories: 120,
    nf_total_fat: 14,
    nf_total_carbohydrate: 0,
    nf_protein: 0,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Tbsp"
}, {
    item_name: "Papaya",
    brand_name: "USDA",
    nf_calories: 62,
    nf_total_fat: 0,
    nf_total_carbohydrate: 16,
    nf_protein: 1,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Parsley",
    brand_name: "usda",
    nf_calories: 22,
    nf_total_fat: .5,
    nf_total_carbohydrate: 3.8,
    nf_protein: 1.8,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Passion Fruit",
    brand_name: "USDA",
    nf_calories: 229,
    nf_total_fat: 1.7,
    nf_total_carbohydrate: 55,
    nf_protein: 5,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Peach",
    brand_name: "USDA",
    nf_calories: 59,
    nf_total_fat: 0,
    nf_total_carbohydrate: 14,
    nf_protein: 1,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Peach"
}, {
    item_name: "Peanut Butter",
    brand_name: "USDA",
    nf_calories: 95,
    nf_total_fat: 8,
    nf_total_carbohydrate: 3,
    nf_protein: 4,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Tbsp"
}, {
    item_name: "Peanut Butter Powder",
    brand_name: "USDA",
    nf_calories: 23,
    nf_total_fat: 0,
    nf_total_carbohydrate: 2,
    nf_protein: 3,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Tbsp"
}, {
    item_name: "Pear",
    brand_name: "USDA",
    nf_calories: 102,
    nf_total_fat: 0,
    nf_total_carbohydrate: 27,
    nf_protein: 0,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Pear"
}, {
    item_name: "Peas",
    brand_name: "USDA",
    nf_calories: 118,
    nf_total_fat: 0.6,
    nf_total_carbohydrate: 21,
    nf_protein: 8,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Pecans",
    brand_name: "USDA",
    nf_calories: 684,
    nf_total_fat: 71,
    nf_total_carbohydrate: 14,
    nf_protein: 9,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Pecan Butter",
    brand_name: "USDA",
    nf_calories: 87.5,
    nf_total_fat: 8,
    nf_total_carbohydrate: 3,
    nf_protein: 2,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Tbsp"
}, {
    item_name: "Pistachio Butter",
    brand_name: "USDA",
    nf_calories: 78,
    nf_total_fat: 6,
    nf_total_carbohydrate: 4,
    nf_protein: 3,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Tbsp"
}, {
    item_name: "Plum",
    brand_name: "USDA",
    nf_calories: 30,
    nf_total_fat: 0,
    nf_total_carbohydrate: 8,
    nf_protein: .5,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Plum"
}, {
    item_name: "Pineapple",
    brand_name: "USDA",
    nf_calories: 82,
    nf_total_fat: 0,
    nf_total_carbohydrate: 22,
    nf_protein: 1,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Pollen, Bee",
    item_link: "https://amzn.to/2JybxlJ",
    brand_name: "USDA",
    nf_calories: 10,
    nf_total_fat: 0,
    nf_total_carbohydrate: 2,
    nf_protein: 1,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Tsp"
}, {
    "item_name": "Pomegranate Seeds",
    "nf_calories": 144,
    "nf_total_fat": 1,
    "nf_total_carbohydrate": 33,
    "nf_protein": 1.5,
    "nf_serving_size_qty": 1,
    "nf_serving_size_unit": "Cup"
}, {
    item_name: "Pomegranate juice",
    nf_calories: 136,
    nf_total_fat: 1,
    nf_total_carbohydrate: 33,
    nf_protein: 0,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Potato Boiled",
    brand_name: "usda",
    nf_calories: 68,
    nf_total_fat: 0,
    nf_total_carbohydrate: 16,
    nf_protein: 1.5,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Protein Powder",
    item_link: "/best-protein-app/",
    nf_calories: 120,
    nf_total_fat: 1,
    nf_total_carbohydrate: 3,
    nf_protein: 24,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "scoop"
}, {
    item_name: "Psyllium Husk",
    nf_calories: 15,
    nf_total_fat: 0,
    nf_total_carbohydrate: 4,
    nf_protein: 0,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Tsp"
}, {
    item_name: "Pumpkin Raw",
    brand_name: "usda",
    nf_calories: 30,
    nf_total_fat: 0,
    nf_total_carbohydrate: 8,
    nf_protein: 1.2,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Pumpkin Cooked",
    brand_name: "usda",
    nf_calories: 49,
    nf_total_fat: 0,
    nf_total_carbohydrate: 12,
    nf_protein: 1.8,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Pumpkin Seed Butter",
    brand_name: "USDA",
    nf_calories: 80,
    nf_total_fat: 6.5,
    nf_total_carbohydrate: 2,
    nf_protein: 5,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Tbsp"
}, {
    item_name: "Raspberries",
    brand_name: "USDA",
    nf_calories: 65,
    nf_total_fat: .8,
    nf_total_carbohydrate: 15,
    nf_protein: 1.5,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Raisins",
    brand_name: "USDA",
    nf_calories: 129,
    nf_total_fat: .2,
    nf_total_carbohydrate: 34,
    nf_protein: 1.3,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Small Box"
}, {
    item_name: "Rice, Brown, Raw",
    brand_name: "usda",
    nf_calories: 684,
    nf_total_fat: 5,
    nf_total_carbohydrate: 143,
    nf_protein: 15,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Rice, Brown, Cooked",
    brand_name: "usda",
    nf_calories: 216,
    nf_total_fat: 1.8,
    nf_total_carbohydrate: 45,
    nf_protein: 5,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Rice, White, Raw",
    brand_name: "usda",
    nf_calories: 675,
    nf_total_fat: 1.2,
    nf_total_carbohydrate: 148,
    nf_protein: 13,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Rice, White, Cooked",
    brand_name: "usda",
    nf_calories: 206,
    nf_total_fat: .4,
    nf_total_carbohydrate: 45,
    nf_protein: 4.3,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Rice Milk",
    brand_name: "rice dream",
    nf_calories: 120,
    nf_total_fat: 2.5,
    nf_total_carbohydrate: 23,
    nf_protein: 1,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Romaine Lettuce",
    brand_name: "rice dream",
    nf_calories: 8,
    nf_total_fat: 0,
    nf_total_carbohydrate: 1.5,
    nf_protein: .6,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Soy Milk",
    brand_name: "Silk",
    nf_calories: 110,
    nf_total_fat: 4.5,
    nf_total_carbohydrate: 9,
    nf_protein: 8,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Spinach",
    brand_name: "USDA",
    nf_calories: 7,
    nf_total_fat: 0,
    nf_total_carbohydrate: 1,
    nf_protein: 1,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Spirulina",
    brand_name: "USDA",
    nf_calories: 23,
    nf_total_fat: 1,
    nf_total_carbohydrate: 2,
    nf_protein: 5,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Tbsp"
}, {
    item_name: "Squash, Winter",
    brand_name: "USDA",
    nf_calories: 76,
    nf_total_fat: 1,
    nf_total_carbohydrate: 18,
    nf_protein: 2,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Stevia",
    brand_name: "USDA",
    nf_calories: 0,
    nf_total_fat: 0,
    nf_total_carbohydrate: 0,
    nf_protein: 0,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Tbsp"
}, {
    item_name: "Strawberries",
    brand_name: "USDA",
    nf_calories: 46,
    nf_total_fat: 0,
    nf_total_carbohydrate: 11,
    nf_protein: 1,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Sunflower Seed Butter",
    brand_name: "USDA",
    nf_calories: 90,
    nf_total_fat: 6,
    nf_total_carbohydrate: 4,
    nf_protein: 4.5,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Tbsp"
}, {
    item_name: "Swiss Chard",
    brand_name: "USDA",
    nf_calories: 7,
    nf_total_fat: 0,
    nf_total_carbohydrate: 1.3,
    nf_protein: .6,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Tahini",
    brand_name: "USDA",
    nf_calories: 95,
    nf_total_fat: 8.5,
    nf_total_carbohydrate: 3.5,
    nf_protein: 3,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Tbsp"
}, {
    item_name: "Tofu, silken",
    brand_name: "House Foods",
    nf_calories: 60,
    nf_total_fat: 2,
    nf_total_carbohydrate: 2,
    nf_protein: 5,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "3 Ounces"
}, {
    item_name: "Tomato Juice",
    brand_name: "usda",
    nf_calories: 42,
    nf_total_fat: .1,
    nf_total_carbohydrate: 10,
    nf_protein: 1.8,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Tuna",
    brand_name: "brunswick",
    nf_calories: 200,
    nf_total_fat: 4,
    nf_total_carbohydrate: 1,
    nf_protein: 40,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Vegetable Oil",
    brand_name: "USDA",
    nf_calories: 120,
    nf_total_fat: 13.6,
    nf_total_carbohydrate: 0,
    nf_protein: 0,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Tbsp"
}, {
    item_name: "Walnuts",
    brand_name: "USDA",
    nf_calories: 765,
    nf_total_fat: 76,
    nf_total_carbohydrate: 16,
    nf_protein: 18,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Watermelon",
    brand_name: "USDA",
    nf_calories: 46,
    nf_total_fat: .2,
    nf_total_carbohydrate: 11,
    nf_protein: .9,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Wheat Grass",
    brand_name: "USDA",
    nf_calories: 15,
    nf_total_fat: 0,
    nf_total_carbohydrate: 2,
    nf_protein: 1,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "2 fl oz shot"
}, {
    item_name: "Xanthan Gum",
    brand_name: "bobs red mill",
    nf_calories: 30,
    nf_total_fat: 0,
    nf_total_carbohydrate: 7,
    nf_protein: 0,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Tbsp"
}, {
    item_name: "Yogurt - skim milk",
    brand_name: "USDA",
    nf_calories: 137,
    nf_total_fat: 0,
    nf_total_carbohydrate: 19,
    nf_protein: 14,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Yogurt - whole milk",
    brand_name: "horizon",
    nf_calories: 149,
    nf_total_fat: 8,
    nf_total_carbohydrate: 11,
    nf_protein: 8.5,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}, {
    item_name: "Zucchini",
    brand_name: "USDA",
    nf_calories: 21,
    nf_total_fat: 0.4,
    nf_total_carbohydrate: 4,
    nf_protein: 1.5,
    nf_serving_size_qty: 1,
    nf_serving_size_unit: "Cup"
}];

