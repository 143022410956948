import React from "react";
import TechIndex from "./TechIndex";
import { Helmet } from "react-helmet";

const TechIndexWithHeader = () => {
  return (
    <>
      <Helmet>
        <title>Technology Calculators</title>
        <meta
          name="description"
          content="The internet's best tech calculators."
        />
      </Helmet>
      <TechIndex />
    </>
  );
};

export default TechIndexWithHeader;
