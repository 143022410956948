import React from "react";

const Description = ({title, mainText}) => {
  return (
    <div className="container mx-auto p-6 space-y-6">
      <main className="space-y-4">
        <section className="p-6 bg-neutral rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold text-accent">
            {title}
          </h2>
          <div className="mt-2 text-gray-100 space-y-4" dangerouslySetInnerHTML={{ __html: mainText }}></div>
        </section>
      </main>
    </div>
  );
};

export default Description;
